import { get_from_localStorage, invokeApi } from "src/utils";

export const _current_team_list = async (data) => {
  const requestObj = {
    path: `api/workspace/workspace_team_list_admin`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _current_clients_list = async (data) => {
  const requestObj = {
    path: `api/workspace/workspace_team_list_only_client`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_rate_for_client = async (data) => {
  const requestObj = {
    path: `api/workspace/update_client_price_v1`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_rate_of_client = async (data) => {
  const requestObj = {
    path: `api/workspace/client_price_detail`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _search_users = async (data, param) => {
  const requestObj = {
    path: `api/customer/search_customer?text=${param}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _remove_user_from_workspace = async (data) => {
  const requestObj = {
    path: `api/workspace/remove_user`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _team_working_on_tasks_list = async (data) => {
  const requestObj = {
    path: `api/task/show_user_working_tasks_in_team`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

