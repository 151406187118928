import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Paper,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Checkbox,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  Tooltip,
  Avatar,
  Badge,
} from "@mui/material";
import { get_zone_date, isColorLight, replacedText } from "src/utils";
import {
  CircularLoader,
  LabelPopup,
  PriorityPopover,
  StatusPopover,
  TaskCreatedAt,
  TaskDatePick,
  TaskDatePicker,
  TeamMembers,
  TeamPicker,
  UserAvatar,
} from "..";
import Iconify from "../Iconify";
import Label from "../Label";
import { useAppContext } from "src/hooks";
import { format, parseISO } from "date-fns";
import { _project_team } from "src/DAL";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

/* <<--------------------------------------------------------->> */

const stack_props = {
  direction: "row",
  alignItems: "center",
  columnGap: 0.5,
};
const icon_color = "#637381";
const menu_item_icon_size = { height: "1rem", width: "1rem" };
/* <<--------------------------------------------------------->> */
const AllTasksVariantUnOptimized = ({
  task,
  taskTeam,
  onOpenComments,
  onTaskAction,
  onSetSelectedTask,
  projects = [],
  getListing,
  onChangeTags,
  completed,
}) => {
  const { dispatch_get_user_profile } = useAppContext();
  const navigate = useNavigate();
  const userProfile = dispatch_get_user_profile();

  const matches_break_point = useMediaQuery("(max-width:600px)");
  const { createdAt, end_date, creator, image, order, priority, project_id } =
    task;
  const { task_title, task_action_by, creator_profile, time_track } = task;
  const role = task_action_by;
  const {
    task_status,
    task_type,
    team,
    workspace_id,
    _id,
    extra_status,
    tag_colors,
  } = task;
  const [open, setOpen] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [taskProjectTeam, setTaskProjectTeam] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [projectTags, setProjectTags] = useState(() => {
    if (task?.project_data) {
      return task.project_data.tag_colors;
    } else {
      return projects.find((project) => project._id == task.project.id)
        ?.tag_colors;
    }
  });
  // const [tagExpand, setTagExpand] = useState(false);
  const { tagExpand, setTagExpand } = useAppContext();
  // ---------------------------------------------------------
  const getCommentTooltipTitle = () => {
    if (!task) {
      return "Comments";
    }
    if (task.unread_comments === false) {
      return "0 unread comments";
    }
    if (task.unread_comments === true) {
      let plural = task.unread_comments_count > 0;
      return `${task.unread_comments_count} unread ${
        plural ? "comments" : "comment"
      }`;
    }
  };
  const handleNavProject = (_task) => {
    console.log("nav to", task);

    navigate(`/workspaces/projects/${task.project.id}`, {
      state: {
        scroll_to_task: _task._id,
        expand_section_id: _task.section_id,
      },
    });
  };
  const handleOpen = (e, task) => {
    console.log(task, "on-select-task");
    onSetSelectedTask(task);
    setOpen(e.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const handleOpenSubmenu = (e, task) => {
    e.stopPropagation();
    setSubmenuOpen(e.currentTarget);
  };
  const handleCloseSubmenu = () => {
    setSubmenuOpen(null);
  };
  const handleAction = (action, data) => {
    onTaskAction(action, data);
  };
  const handleCopy = (task) => {
    onTaskAction("copy_task_link", task);
  };
  const onStatusCheckChange = (e) => {
    let checked = e.target.checked;
    const data = {
      checked: checked,
      task: task,
      is_checkbox: true,
    };
    handleAction("change_status", data);
  };
  const onStatusChange = (_data) => {
    const data = {
      task: task,
      status: _data.value,
      is_checkbox: false,
    };
    handleAction("change_status", data);
  };
  const onPriorityChange = (_data) => {
    const data = { task: task, priority: _data.value };
    handleAction("change_priority", data);
  };
  const onDateChange = (newDate) => {
    console.log(newDate, "new-date-on-change");
    const data = { task: task, endDate: newDate };
    handleAction("change_date", data);
  };
  const onOpenDetail = (_task) => {
    handleAction("open_task_detail", _task);
  };
  const canRemoveTeam = (_targetUser, _task) => {
    console.log(role, "role");
    let current_user_id = userProfile.user_id;
    let target_user_id = _targetUser.user;
    if (String(role) === "admin") {
      return true;
    }
    if (String(role) === "manager") {
      return true;
    }
    if (String(current_user_id) === String(target_user_id)) {
      console.log("cannot remove yourself");
      return false;
    }
    if (String(role) === "team") {
      let found_index_in_team = undefined;
      _task.team.map((item, index) => {
        if (String(item.user) === String(target_user_id)) {
          found_index_in_team = index;
          console.log(item, "ff");
        }
      });
      console.log(found_index_in_team, "found_index_in_team");
      if (
        String(current_user_id) ===
        String(_task.team_assign_by[found_index_in_team])
      ) {
        console.log("you can remove");
      }
      return true;
    }
    return false;
  };
  const onTeamChange = (target_user, task_team, is_selected, action) => {
    const data = {
      task: task,
      taskTeam: task_team,
      targetUser: target_user,
      isSelected: is_selected,
      action: action,
    };
    console.log(data, "team-req");
    if (String(data.action) === "remove") {
      let res = canRemoveTeam(data.targetUser, data.task);
      console.log(res, "canRemoveTeam");
      if (res === false) {
        return;
      }
    }
    handleAction("change_members", data);
  };
  const isChecked = () => {
    if (String(task_status) === "3") {
      return true;
    }

    if (String(task_status) === "4") {
      return true;
    }
    return false;
  };

  const allowDateAction = () => {
    if (task.creator_profile.user_id === userProfile.user_id) {
      return true;
    }
    if (task.user_task_status === true) {
      return true;
    }
    if (String(role) === "admin") {
      return true;
    }
    if (String(role) === "manager") {
      return true;
    }
    if (String(role) === "client" || String(role) === "client_team") {
      return true;
    }
    if (String(role) === "team") {
      return false;
    }
    return false;
  };
  const excludedStatusOptions = () => {
    console.log(task,"task")
    const currentUserRole=task.role;

    console.log(currentUserRole,"currentUserRole")
    console.log(creator_profile,"creator_profile")
  
    if (!currentUserRole) {
      return [5];
    }
    if (creator_profile.user_id === dispatch_get_user_profile().user_id) {
      return [5];
    }
    if (currentUserRole === "admin") {
      return [5];
    }
    if (currentUserRole === "manager") {
      return [5];
    }
    if (currentUserRole === "client" || currentUserRole === "client_team") {
     
      return [5];
    }
    if (currentUserRole === "team") {
      
      return [4, 5]; /* exclude verified status for team */
    }
    return [];
   
  };
  const getTaskProjectTeam = async () => {
    // setLoadingTeam(true);
    const result = await _project_team(null, task.project.id);
    if (result.code === 200) {
      setTaskProjectTeam(result.project_team_list.team);
      setLoadingTeam(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoadingTeam(false);
    }
  };
  const handleScroll = () => {
    const elm = document.getElementById(task._id);
    if (elm) {
      if (task.scroll_to_view === true) {
        elm.scrollIntoView({ block: "center", behavior: "auto" });
        setTimeout(() => {
          elm.classList.add("highlight-task");
        }, 500);
        setTimeout(() => {
          elm.classList.remove("highlight-task");
        }, 3000);
      }
    }
  };

  useEffect(() => {
    handleScroll();
    return () => {
      setTaskProjectTeam([]);
    };
  }, []);
  /* <<--------------------------------------------------------->> */
  return (
    <React.Fragment>
      <Paper
        id={task._id}
        className="task-card-el"
        onClick={() => {
          console.log(task, "task");
          onOpenDetail(task);
        }}
        elevation={3}
        sx={{
          width: 1,
          borderRadius: "0.3rem",
          p: 0.7,
          pt: 0.9,
          position: "relative",
          pr: 7.5,
        }}
      >
        <Stack spacing={0.3}>
          <Stack {...stack_props}>
            <Checkbox
              sx={{ mb: "auto", p: "4px" }}
              value={task_status}
              onChange={onStatusCheckChange}
              checked={isChecked()}
              onClick={(e) => e.stopPropagation()}
            />
            <Typography
              role="button"
              sx={{ cursor: "pointer", overflow: "hidden" }}
            >
              <div
                dangerouslySetInnerHTML={replacedText(task_title)}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              />
            </Typography>
          </Stack>
          <Grid container>
            <Grid
              sx={{ paddingLeft: "9px", display: "flex", alignItems: "center" }}
              item
              flexGrow={1}
            >
              <Grid id="actions" direction="row" container>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  item
                  sm={3}
                  xs={12}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <TaskDatePick
                      hasPortal
                      value={end_date}
                      onChange={(newDate) => onDateChange(newDate)}
                      task={task}
                      disabled={!allowDateAction()}
                    />
                  </span>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  sm={3}
                  xs={12}
                >
                  <Stack
                    justifyContent="center"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tooltip
                      title={`created by ${
                        creator_profile.first_name +
                        " " +
                        creator_profile.last_name
                      }`}
                    >
                      <div>
                        <UserAvatar
                          sx={{ width: 35, height: 35 }}
                          image={creator_profile.image}
                          alt="task creator"
                          name={
                            creator_profile.first_name +
                            " " +
                            creator_profile.last_name
                          }
                        />
                      </div>
                    </Tooltip>
                    <TaskCreatedAt date={createdAt} />
                    <PriorityPopover
                      onChange={(data) => onPriorityChange(data)}
                      allowOpen
                      value={priority}
                    />
                    <StatusPopover
                      onChange={(data) => onStatusChange(data)}
                      extraStatus={extra_status}
                      allowOpen={true}
                      excludedOptions={excludedStatusOptions()}
                      value={task_status}
                    />
                    <div className="pointer">
                      <Tooltip title={"Comments"}>
                        <Stack
                          onClick={() => onOpenComments(task)}
                          direction="row"
                          alignItems="center"
                          spacing={0.1}
                        >
                          <Badge
                            badgeContent=""
                            color="error"
                            invisible={task.task_comment_unread_count == 0}
                            variant="dot"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Iconify
                              sx={{
                                height: 17,
                                width: 17,
                                color: icon_color,
                              }}
                              icon="majesticons:comment-text"
                            />
                          </Badge>
                          <Typography variant="body2">
                            {task.task_comment.length}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </div>
                    <div onClick={() => handleCopy(task)} className="pointer">
                      <Tooltip title="Copy task link">
                        <div>
                          <Iconify
                            sx={{ height: 17, width: 17, color: icon_color }}
                            icon="clarity:copy-line"
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <LabelPopup
                      projectId={task.project.id}
                      projectTags={projectTags}
                      setProjectTags={setProjectTags}
                      task={task}
                      sections={[]}
                      getFilteredTasksBySection={() => {}}
                      getListing={getListing}
                      onChangeTags={onChangeTags}
                    />
                  </Stack>
                </Grid>
                <Grid item sm={2} xs={12}>
                  {!completed && (
                    <Stack
                      justifyContent="center"
                      columnGap={0.7}
                      direction="row"
                      alignItems="center"
                      sx={{ marginTop: matches_break_point ? 0.3 : "initial" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {team.length > 0 && (
                        <Box
                          sx={{
                            marginLeft: matches_break_point
                              ? "27px"
                              : "initial",
                          }}
                        >
                          <TeamMembers
                            disabled={completed}
                            allowOpen={true}
                            taskTeam={team}
                            projectTeam={taskTeam}
                            onChange={(
                              target_user,
                              taskTeam,
                              is_selected,
                              action
                            ) =>
                              onTeamChange(
                                target_user,
                                taskTeam,
                                is_selected,
                                action
                              )
                            }
                          />
                        </Box>
                      )}
                      <TeamPicker
                        onChange={(
                          target_user,
                          taskTeam,
                          is_selected,
                          action
                        ) =>
                          onTeamChange(
                            target_user,
                            taskTeam,
                            is_selected,
                            action
                          )
                        }
                        allowOpen={!completed}
                        taskTeam={team}
                        projectTeam={taskProjectTeam}
                        onOpen={getTaskProjectTeam}
                        loading={loadingTeam}
                      />
                    </Stack>
                  )}
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span>
                      <Tooltip title="Project name">
                        <Typography
                          onClick={() => handleNavProject(task)}
                          noWrap
                          className="project-name-btn"
                        >
                          {task.project.name}
                        </Typography>
                      </Tooltip>
                    </span>
                    <div>
                      {(role == "team" ||
                        role == "admin" ||
                        role == "manager") &&
                        time_track.estimated_time && (
                          <div>
                            <Label
                              variant="ghost"
                              color="info"
                              startIcon={
                                <Iconify icon={"ic:outline-access-time"} />
                              }
                            >
                              Estimate: {time_track.estimated_time}
                            </Label>
                          </div>
                        )}
                      {(role == "team" ||
                        role == "admin" ||
                        role == "manager") &&
                        task_status == 3 &&
                        time_track.actual_time && (
                          <div>
                            <Label
                              variant="ghost"
                              color="info"
                              startIcon={
                                <Iconify icon={"ic:outline-access-time"} />
                              }
                            >
                              Completed: {time_track.actual_time}
                            </Label>
                          </div>
                        )}

                      {(role == "admin" || role == "manager") &&
                        time_track.final_time && (
                          <div>
                            <Label
                              variant="ghost"
                              color="info"
                              startIcon={
                                <Iconify icon={"ic:outline-access-time"} />
                              }
                            >
                              Billable: {time_track.final_time}
                            </Label>
                          </div>
                        )}
                      {(role == "client" || role == "client_team") &&
                        time_track.final_time &&
                        task_status == 3 && (
                          <div>
                            <Label
                              variant="ghost"
                              color="info"
                              startIcon={
                                <Iconify icon={"ic:outline-access-time"} />
                              }
                            >
                              Completed: {time_track.final_time}
                            </Label>
                          </div>
                        )}
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          flexWrap={"wrap"}
          onClick={(e) => e.stopPropagation()}
          sx={{ pl: 0.9 }}
        >
          {tag_colors.map((tag) => (
            <Tooltip title={tag.name ? tag.name : ""} key={tag._id}>
              <div
                style={{
                  backgroundColor: tag.color_code,
                  borderRadius: "5px",
                  minWidth: "50px",
                  maxWidth: "100px",
                  maxHeight: tagExpand ? "20px" : "7px",
                  minHeight: "7px",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: isColorLight(tag.color_code) ? "#000" : "#fff",
                  transition: "max-height 0.3s ease",
                  cursor: "pointer",
                }}
                className="px-1"
                onClick={() => setTagExpand((prev) => !prev)}
              >
                {tagExpand && tag.name}
              </div>
            </Tooltip>
          ))}
          
        </Stack>

        {(task.role == "admin" ||
          task.role == "manager" ||
          task.creator_profile.user_id ==
            dispatch_get_user_profile().user_id) && (
          <div onClick={(e) => e.stopPropagation()}>
            <div className="task-card-menu-btn" style={{ right: "10px" }}>
              <IconButton onClick={(e) => handleOpen(e, task)}>
                <Iconify
                  sx={{ color: icon_color }}
                  icon="bi:three-dots-vertical"
                />
              </IconButton>
            </div>
            <Menu
              disableEnforceFocus
              anchorEl={open}
              open={Boolean(open)}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              sx={{
                p: 0.5,
                "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.5,
                  mx: 0.5,
                },
              }}
            >
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onTaskAction("del_task", task);
                  handleClose();
                }}
              >
                Delete
                <span style={{ marginLeft: "auto" }}>
                  <Iconify
                    sx={{ ml: "8px", ...menu_item_icon_size }}
                    icon="dashicons:trash"
                  />
                </span>
              </MenuItem>
            </Menu>
          </div>
        )}
      </Paper>
    </React.Fragment>
  );
};
const AllTasksVariant = ({
  task,
  taskTeam,
  projects,
  onOpenComments,
  onTaskAction,
  onSetSelectedTask,
  userProfile,
  role,
  getListing,
  onChangeTags,
  completed = false,
}) => {
  const optimized = useMemo(() => {
    return (
      <AllTasksVariantUnOptimized
        task={task}
        taskTeam={taskTeam}
        onOpenComments={onOpenComments}
        onTaskAction={onTaskAction}
        onSetSelectedTask={onSetSelectedTask}
        userProfile={userProfile}
        role={role}
        projects={projects}
        getListing={getListing}
        onChangeTags={onChangeTags}
        completed={completed}
      />
    );
  }, [task, taskTeam, userProfile, role]);

  return (
    <>
      <Suspense
        fallback={
          <>
            <CircularLoader />
          </>
        }
      >
        {optimized}
      </Suspense>
    </>
  );
};
export default AllTasksVariant;
