import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, DialogActions, DialogContent, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "./Iconify";
// ----------------------------------------------------------

function SimpleDialog(props) {
  const {
    onClose,
    selectedValue,
    open,
    title,
    message,
    onAgree,
    disableDismiss,
    loading,
    ExtraComponent,
    cancelbuttonText,
    classes,
    sx,
    itemName,
  } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };
  const responsive = {
    width: "40vw",
    ...sx, 
    "@media (max-width: 992px)": { 
      width: "60vw", 
    },
    "@media (max-width: 768px)": { 
      width: "80vw", 
    },
  };

  return (
    <Dialog
      PaperProps={{ sx:  responsive  }}
      onClose={disableDismiss ? () => null : handleClose}
      open={open}
      classes={classes}
    >
      <DialogTitle>{title ? title : "Untitled"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack>
            {message && (
              <Typography variant="body">
                {message ? message : "untitled"}
              </Typography>
            )}
            {itemName && (
              <Typography fontWeight={600} variant="body" mt={0.5}>
                "{itemName}"
              </Typography>
            )}
          </Stack>

          {ExtraComponent && ExtraComponent}
        </Stack>
      </DialogContent>
      <DialogActions sx={{padding:"16px 10px"}}>
        <div className="d-flex align-items-center justify-content-end gap-1">
          {onAgree && (
            <LoadingButton
            sx={{
              backgroundColor: '#C23232',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#A12B2B',
              },
              '& .MuiCircularProgress-root': {
                color: '#fff', 
              },
            }}
            loading={loading}
            onClick={onAgree}
          >
            Yes
          </LoadingButton>
          )}
          <Button color="inherit" onClick={handleClose}>
            {cancelbuttonText ? cancelbuttonText : "Cancel"}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default function MuiDialog({
  open,
  onToggle,
  title,
  message,
  disableDismiss,
  onAgree,
  loading = false,
  ExtraComponent,
  cancelbuttonText = "",
  cancelFunction,
  classes = {},
  sx = {},
  itemName,
}) {
  const handleClose = (value) => {
    if (!cancelbuttonText) {
      onToggle(false);
    } else {
      cancelFunction();
    }
  };

  return (
    <div>
      <SimpleDialog
        message={message}
        title={title}
        cancelbuttonText={cancelbuttonText}
        open={open}
        onAgree={onAgree}
        onClose={handleClose}
        disableDismiss={disableDismiss}
        loading={loading}
        ExtraComponent={ExtraComponent}
        classes={classes}
        sx={sx}
        itemName={itemName}
      />
    </div>
  );
}
MuiDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  disableDismiss: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  ExtraComponent: PropTypes.node,
  cancelbuttonText: PropTypes.string,
  cancelFunction: PropTypes.func,
};
