import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Tooltip, Typography } from "@mui/material";
import {
  PriorityPopover,
  StatusPopover,
  TaskCreatedAt,
  UserAvatar,
} from "src/components";
import { truncateText } from "src/utils";
import { useAppContext } from "src/hooks";
import moment from "moment";

/* <<--------------------------------------------------------->> */

function DashboardTaskCard({ task, onTaskAction, selectedUser , isWorking}) {
  const { dispatch_get_user_profile } = useAppContext();
  const userId = dispatch_get_user_profile().user_id;

  const handleAction = (action, data) => {
    onTaskAction(action, data);
  };

  const handleOpenDetail = () => {
    handleAction("open_task_detail", task);
  };

  const formatSeconds = (seconds) => {
    if (seconds < 60) {
      return `${seconds} sec`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return minutes === 1 ? `${minutes} min` : `${minutes} mins`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      if (minutes === 0) {
        return `${hours} hr`;
      } else {
        return `${hours} hr ${minutes} min`;
      }
    }
  };

  return (
    <Card
      className="dashboard-tasks-card"
      variant="outlined"
      onClick={handleOpenDetail}
    >
      <CardContent className="pb-0" sx={{ p: 0 }}>
        <Typography className="all-activetask-title mb-2">
          {truncateText(task.task_title, 65)}
        </Typography>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="d-flex align-items-center gap-2">
            <Tooltip
              title={`Created by ${task.creator_profile.first_name} ${task.creator_profile.last_name}`}
            >
              <div>
                <UserAvatar
                  sx={{
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                  }}
                  image={task.creator_profile.image}
                  name={task.creator_profile.first_name}
                  alt={`${task.creator_profile.first_name} ${task.creator_profile.last_name}`}
                />
              </div>
            </Tooltip>
            {/* <TaskCreatedAt date={task.createdAt} /> */}
            <PriorityPopover allowOpen={false} value={task.priority} />
            <StatusPopover allowOpen={false} value={task.task_status} />
            {/* Task status button ----------------- */}
            {task.time_tracking &&
              (selectedUser?.user_id
                ? task.time_tracking.filter((time_block) => time_block.assignee.user_id === selectedUser.user_id)
                : task.time_tracking.filter((time_block) => time_block.assignee.user_id === userId)).map((time_block, i) => (
                  <span
                    key={i}
                    className="chip-tag"
                    style={{
                      color:
                        time_block.tracking_status === "start"
                          ? "white"
                          : "black",
                      backgroundColor:
                        time_block.tracking_status === "start"
                          ? "#3366FF"
                          : "#FFC107",
                    }}
                  >
                    {time_block.tracking_status === "start"
                      ? "Running"
                      : "Paused"}
                  </span>
                ))}
          </div>
          <Tooltip title="Project">
            <span className="chip-tag">{task.project.name}</span>
          </Tooltip>
        </div>
      </CardContent>
      {/* user Working on task ---------------- */}
      {isWorking && task.time_tracking &&
        (selectedUser?.user_id
          ? task.time_tracking.filter((time_block) => time_block.assignee.user_id === selectedUser.user_id)
          : task.time_tracking.filter((time_block) => time_block.assignee.user_id === userId)).map((time_block, i) => (
            <div className="working-on-task" key={i}>
              <Typography fontSize="10px" color="#637381" className="mt-2">
                <span>
                  {time_block.assignee.user_id === userId ? (
                    <b>You</b>
                  ) : (
                    <b>{`${time_block.assignee.first_name} ${time_block.assignee.last_name}`}</b>
                  )}
                </span>{" "}
                <span>
                  spent <b>{formatSeconds(time_block.tracked_time)}</b>
                </span>{" "}
                <span>
                  since{" "}
                  <b>
                    {moment(time_block.created_time).format("ddd DD MMM YYYY")}
                  </b>
                </span>
              </Typography>
            </div>
          ))}
    </Card>
  );
}

export default DashboardTaskCard;
