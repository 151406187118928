import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import moment from "moment";
import { PriorityPopover, UserAvatar } from "src/components";

export default function CheckListDialog({ open, onClose, item, title }) {
  
  if (!item) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="dialog-title"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ padding: "10px 24px" }}>
        <Typography>{item?.name}</Typography>

        <hr style={{ color: "#d0d0d0" }} />
        <div className="">
          <Typography fontSize={"10px"} fontWeight={500}>
            Created By
          </Typography>
          <div
            className="d-flex align-items-center gap-2"
            style={{
              backgroundColor: "#F6F7F8",
              padding: "5px 10px",
              borderRadius: "4px",
              width: "max-content",
            }}
          >
            <UserAvatar
              sx={{
                width: 25,
                height: 25,
              }}
              image={item?.creator_profile?.image}
              alt={item?.creator_profile?.first_name}
            />
            <Typography fontSize={"12px"}>
              {item?.creator_profile?.first_name}{" "}
              {item?.creator_profile?.last_name} - {" "}
              {moment(item?.createdAt).format("DD MMM YYYY hh:mm A")}
            </Typography>
            <PriorityPopover allowOpen={false} value={item.priority} />
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
