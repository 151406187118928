import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material
import { Menu, Button, MenuItem, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useTransition } from 'react';
// ----------------------------------------------------------------------

export default function SectionSelect({ title, selectedIndex, onFilterOptionClick, options, sx }) {
  const [open, setOpen] = useState(null);
  const [isPending, startTransition] = useTransition();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleClick = (_option, _index) => {
    setOpen(null);
    onFilterOptionClick(_option, _index);
  };
  const getWidth = () => {
    let button = document.getElementById('button-select');
    if (button) {
      let client_width = button.clientWidth;
      return `${client_width}px`;
    }
    return `${250}px`;
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string?.length) {
      return string;
    }
    if (string?.length > maxLength) {
      let result = string.slice(0, maxLength).concat(" " + "...");
      return result;
    }
    return string;
  };
  // ---------------------------------------------------------
  return (
    <>
      <Button
        className='btn-select'
        id='button-select'
        color='inherit'
        onClick={handleOpen}
        sx={{
          bgcolor: (theme) => theme.palette.action.hover,
          alignItems: 'center',
          width: '100%',
          ...sx,
        }}
        endIcon={
          <Iconify
            sx={{ color: (theme) => theme.palette.primary.main, height: '1.4rem', width: '1.4rem' }}
            icon={open ? 'ic:baseline-keyboard-arrow-up' : 'ic:baseline-keyboard-arrow-down'}
          />
        }
      >
        <Typography component='span' variant='subtitle2' fontWeight='bold'>
          {title ? title : `Sort By`}
          <Typography
            fontWeight='bold'
            sx={{
              display: 'inline',
            }}
          >
            &nbsp;:
          </Typography>
          &nbsp;
        </Typography>
        <Typography
          component='span'
          noWrap
          textAlign='center'
          variant='subtitle2'
          sx={{
            color: 'text.primary',
          }}
        >
          {/* {truncateText(options.find(option => option.is_default === true)?.section_title , 22)} */}
          {truncateText(options[selectedIndex]?.section_title, 22)}
        </Typography>
      </Button>
      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        PaperProps={{ sx: { marginTop: window.innerWidth<600?'3.5rem':'2.5rem', width: getWidth() , maxHeight:"80%"} }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            // selected={option.is_default}
            selected={selectedIndex === index}
            onClick={() => handleClick(option, index)}
            sx={{
              justifyContent: 'center',
            }}
          >
            <Typography justifyContent='center' variant='body2' noWrap>
              {option.section_title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
SectionSelect.propTypes = {
  title: PropTypes.string,
  selectedIndex: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  sx: PropTypes.object,
  onFilterOptionClick: PropTypes.func.isRequired,
};
