import React, { Suspense, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Paper,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Checkbox,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  Tooltip,
  Avatar,
} from "@mui/material";
import {
  PriorityPopover,
  StatusPopover,
  TaskDatePicker,
  TeamMembers,
  TeamPicker,
  UserAvatar,
  Iconify,
  CircularLoader,
} from "../../../components";
import { get_zone_date, isColorLight } from "src/utils";
import { useDeferredValue } from "react";
import { useAppContext } from "src/hooks";
/* <<--------------------------------------------------------->> */
const stack_props = {
  direction: "row",
  alignItems: "center",
  columnGap: 0.5,
  paddingLeft: "9px",
};
const icon_color = "#637381";
const menu_item_icon_size = { height: "1rem", width: "1rem" };
/* <<--------------------------------------------------------->> */
const CardTask = ({ props }) => {
  const matches_break_point = useMediaQuery("(max-width:600px)");
  const {
    task,
    currentUserRole,
    projectTeam,
    onTaskAction,
    onSetSelectedTask,
  } = props;
  const { onOpenComments, onOpenDetail, isDraggingOver, userProfile } = props;
  const { created_at, end_date, image, order, status, priority, project_id } =
    task;
  const { task_title, section_id, task_action_by, task_comment_count } = task;
  const { task_action_id, task_comment, task_description, creator_profile } =
    task;
  const { task_status, task_type, team, workspace_id, _id, teamAssignBy } =
    task;
  const [open, setOpen] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  // const [tagExpand, setTagExpand] = useState(false);
  const { tagExpand, setTagExpand } = useAppContext();
  // console.log('task-rerender');
  /* <<--------------------------------------------------------->> */
  const isChecked = () => {
    if (String(task_status) === "3") {
      return true;
    }

    if (String(task_status) === "4") {
      return true;
    }
    return false;
  };
  const handleOpen = (e, task) => {
    console.log(task, "on-select-task");
    onSetSelectedTask(task);
    setOpen(e.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const handleAction = (action, data) => {
    onTaskAction(action, data);
  };
  const onStatusCheckChange = (e) => {
    let checked = e.target.checked;
    const data = { checked: checked, task: task, is_checkbox: true };
    handleAction("change_status", data);
  };
  const onStatusChange = (_data) => {
    const data = {
      task: task,
      section: "taskSection",
      status: _data.value,
      is_checkbox: false,
    };
    handleAction("change_status", data);
  };
  const canUpdateStatus = () => {
    if (task.creator === true) {
      return true;
    }
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (String(currentUserRole) === "team") {
      return false;
    }
    return false;
  };
  const canDelTask = () => {
    if (task.creator === true) {
      return true;
    }
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (String(currentUserRole) === "team") {
      return false;
    }
    return false;
  };
  const onPriorityChange = (_data) => {
    return;
    const data = { task: task, section: "taskSection", priority: _data.value };
    handleAction("change_priority", data);
  };
  const onDateChange = (newDate) => {
    return;
    let formatted = get_zone_date(newDate, "YYYY-MM-DD");
    const data = { task: task, section: "taskSection", endDate: formatted };
    handleAction("change_date", data);
  };
  const canRemoveTeam = (_targetUser, _task) => {
    console.log(currentUserRole, "currentUserRole");
    let current_user_id = userProfile.user._id;
    let target_user_id = _targetUser.user;
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (String(current_user_id) === String(target_user_id)) {
      console.log("cannot remove yourself");
      return false;
    }
    if (String(currentUserRole) === "team") {
      let found_index_in_team = undefined;
      _task.team.map((item, index) => {
        if (String(item.user) === String(target_user_id)) {
          found_index_in_team = index;
        }
      });
      if (
        String(current_user_id) ===
        String(_task.team_assign_by[found_index_in_team])
      ) {
        console.log("you can remove");
      }
      return true;
    }
    return false;
  };
  const onTeamChange = (target_user, task_team, is_selected, action) => {
    return;
    const data = {
      task: task,
      section: "taskSection",
      taskTeam: task_team,
      targetUser: target_user,
      isSelected: is_selected,
      action: action,
    };
    console.log(data, "team-req");
    if (String(data.action) === "remove") {
      let res = canRemoveTeam(data.targetUser, data.task);
      console.log(res, "canRemoveTeam");
      if (res === false) {
        return;
      }
    }
    handleAction("change_members", data);
  };
  return (
    <React.Fragment>
      <Paper
        onClick={() => {
          console.log(task, "task");
          onOpenDetail(task);
        }}
        elevation={3}
        t
        sx={{
          width: 1,
          borderRadius: "0.3rem",
          p: 0.7,
          pt: 0.9,
          position: "relative",
          pr: 5.3,
        }}
      >
        <Stack spacing={0.3}>
          <Stack {...stack_props}>
            {/* <Checkbox
              sx={{ mb: 'auto', p: '4px' }}
              value={task_status}
              onChange={onStatusCheckChange}
              checked={isChecked()}
            /> */}
            <Typography role="button" sx={{ cursor: "pointer" }}>
              {task_title}
            </Typography>
          </Stack>
          <Grid container>
            <Grid
              sx={{ paddingLeft: "9px", display: "flex", alignItems: "center" }}
              item
              flexGrow={1}
            >
              <Grid id="actions" direction="row" container>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  item
                  sm={3}
                  xs={6}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <TaskDatePicker
                      disabled={true}
                      value={end_date}
                      actionAllowed={canUpdateStatus()}
                      onChange={(newData) => onDateChange(newData)}
                    />
                  </span>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  sm={5}
                  xs={6}
                >
                  <Stack
                    justifyContent="center"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {creator_profile && (
                      <Tooltip
                        title={`created by ${
                          creator_profile.first_name +
                          " " +
                          creator_profile.last_name
                        }`}
                      >
                        <div>
                          <UserAvatar
                            sx={{ width: 35, height: 35 }}
                            image={creator_profile.image}
                            alt="task creator"
                            name={
                              creator_profile.first_name +
                              " " +
                              creator_profile.last_name
                            }
                          />
                        </div>
                      </Tooltip>
                    )}
                    <PriorityPopover
                      onChange={(data) => onPriorityChange(data)}
                      allowOpen={false}
                      value={priority}
                    />
                    <StatusPopover
                      onChange={(data) => onStatusChange(data)}
                      allowOpen={canUpdateStatus()}
                      value={task_status}
                    />
                    <div className="pointer">
                      <Tooltip title="Comments">
                        <Stack
                          onClick={() => onOpenComments(task)}
                          direction="row"
                          alignItems="center"
                          spacing={0.1}
                        >
                          <Iconify
                            sx={{ height: 17, width: 17, color: icon_color }}
                            icon="majesticons:comment-text"
                          />
                          <Typography variant="body2">
                            {task.task_comment.length}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Stack
                    justifyContent="flex-start"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                    sx={{ marginTop: matches_break_point ? 0.3 : "initial" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {team.length === 0 ? (
                      <Avatar
                        sx={{
                          height: 29,
                          width: 29,
                          bgcolor: isDraggingOver ? "#68ffa7" : "white",
                        }}
                      >
                        .
                      </Avatar>
                    ) : (
                      <Box
                        sx={{
                          marginLeft: matches_break_point ? "27px" : "initial",
                        }}
                      >
                        <TeamMembers
                          disabled={true}
                          allowOpen={true}
                          taskTeam={team}
                          projectTeam={projectTeam}
                          onChange={(
                            target_user,
                            taskTeam,
                            is_selected,
                            action
                          ) =>
                            onTeamChange(
                              target_user,
                              taskTeam,
                              is_selected,
                              action
                            )
                          }
                        />
                      </Box>
                    )}
                    {/* <TeamPicker
                      onChange={(target_user, taskTeam, is_selected, action) =>
                        onTeamChange(target_user, taskTeam, is_selected, action)
                      }
                      allowOpen
                      taskTeam={team}
                      projectTeam={projectTeam}
                    /> */}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          flexWrap={"wrap"}
          onClick={(e) => e.stopPropagation()}
        >
          {task.tag_colors.map((tag) => (
            <Tooltip title={tag.name ? tag.name : ""} key={tag._id}>
              <div
                style={{
                  backgroundColor: tag.color_code,
                  borderRadius: "5px",
                  minWidth: "50px",
                  maxWidth: "100px",
                  maxHeight: tagExpand ? "20px" : "7px",
                  minHeight: "7px",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: isColorLight(tag.color_code) ? "#000" : "#fff",
                  transition: "max-height 0.3s ease",
                  cursor: "pointer",
                }}
                className="px-1"
                onClick={() => setTagExpand((prev) => !prev)}
              >
                {tagExpand && tag.name}
              </div>
            </Tooltip>
          ))}
        </Stack>
        <div onClick={(e) => e.stopPropagation()}>
          <div className="vrf-task-card-menu-btn">
            <IconButton
              disabled={!canDelTask()}
              sx={{ p: "3px" }}
              onClick={(e) => handleOpen(e, task)}
            >
              <Iconify
                sx={{ color: icon_color }}
                icon="bi:three-dots-vertical"
              />
            </IconButton>
          </div>
          <Menu
            disableEnforceFocus
            anchorEl={open}
            open={Boolean(open)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{
              p: 0.5,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.5,
                mx: 0.5,
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onTaskAction("del_task", task);
                handleClose();
              }}
            >
              Delete
              <span style={{ marginLeft: "auto" }}>
                <Iconify
                  sx={{ ml: "8px", ...menu_item_icon_size }}
                  icon="dashicons:trash"
                />
              </span>
            </MenuItem>
          </Menu>
        </div>
      </Paper>
    </React.Fragment>
  );
};
const VerifiedTaskCard = (props) => {
  const { rerenderDependencies, task } = props;
  const memoedCard = useMemo(
    () => <CardTask props={props} />,
    [...rerenderDependencies]
  );
  /* <<--------------------------------------------------------->> */
  if (!task) {
    return <></>;
  }
  return memoedCard;
};

export default VerifiedTaskCard;
