import { Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Iconify } from "src/components";
import { useAppContext } from "src/hooks";

export default function ActiveTimer() {
  const { activeTasks } = useAppContext();
  const { dispatch_get_user_profile } = useAppContext();
  const userId = dispatch_get_user_profile().user_id;

  const [taskTimes, setTaskTimes] = useState({});
  const startTimeRefs = useRef({});
  const intervalIdRefs = useRef({});
  const navigate = useNavigate();

  const handleNavigate = (task) => {
    const { section_id, _id } = task;
    const project_id = task?.project.id;

    navigate(
      `/workspaces/copied-task-link/${project_id}/${section_id}/${_id}`,
      { state: { task_id: _id } }
    );
  };

  const timerCounter = useCallback(
    (taskId) => {
      const time = taskTimes[taskId] || 0;
      const inToSeconds = Math.floor(time / 1000);
      const hours = Math.floor(inToSeconds / 3600);
      const minutes = Math.floor((inToSeconds % 3600) / 60);
      const seconds = inToSeconds % 60;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    },
    [taskTimes]
  );

  const startTimer = (taskId, initialTime) => {
    startTimeRefs.current[taskId] = Date.now() - initialTime;
    intervalIdRefs.current[taskId] = setInterval(() => {
      setTaskTimes((prevTimes) => ({
        ...prevTimes,
        [taskId]: Date.now() - startTimeRefs.current[taskId],
      }));
    }, 1000);
  };

  useEffect(() => {
    activeTasks.forEach((task) => {
      task?.time_tracking?.forEach((timeTracking) => {
        if (
          timeTracking.assignee.user_id === userId &&
          timeTracking.tracking_status === "start"
        ) {
          const trackedTime = timeTracking.tracked_time * 1000;
          const timeSinceStart =
            Date.now() - new Date(timeTracking.last_time_slot_start_time).getTime();
          const totalTime = trackedTime + timeSinceStart;
          setTaskTimes((prevTimes) => ({
            ...prevTimes,
            [task._id]: totalTime,
          }));
          if (!intervalIdRefs.current[task._id]) {
            startTimer(task._id, totalTime);
          }
        }
      });
    });

    return () => {
      Object.values(intervalIdRefs.current).forEach(clearInterval);
      intervalIdRefs.current = {};
    };
  }, [activeTasks]);


  return (
    <div className="d-flex gap-2">
      {activeTasks.map((task_block) => (
          <div key={task_block._id}>
            <Tooltip title={task_block?.task_title}>
              <Paper
                onClick={() => handleNavigate(task_block)}
                className="active-tasks-paper"
                square
                elevation={0}
                sx={{
                  width: activeTasks.length === 1 ? "400px" : "200px",
                }}
              >
                <Typography className="active-tasks-tittle">
                  {task_block?.task_title}
                </Typography>
                <Typography className="active-tasks-timer">
                  <Iconify
                    icon="ph:clock-duotone"
                    color="primary.main"
                    marginRight="5px"
                    fontSize="18px"
                  />
                  {timerCounter(task_block._id)}
                </Typography>
              </Paper>
            </Tooltip>
          </div>
        ))}
    </div>
  );
}
