import { IconButton, Menu, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import Iconify from "src/components/Iconify";
import CheckListDatePicker from "./CheckListDatePicker";

function CheckListTargetDate({ checklist, onDateChange, canEditTask , completed }) {
  const [open, setOpen] = useState();

  const handleOpen = (e) => {
    setOpen(e.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleDateChange = async (newDate) => {
    handleClose();
    const data = {
      checklistId: checklist._id,
      targetDate: newDate,
    };
    if (onDateChange) {
      await onDateChange(data);
    }
  };

  return (
    <div>
      <CheckListDatePicker
        hasPortal
        completed={completed}
        value={checklist.target_date}
        onChange={handleDateChange}
        task={checklist}
        disabled={false}
      />
    </div>
  );
}

export default CheckListTargetDate;
