import React, { useState } from "react";
import { Card, CardContent, Typography, Stack } from "@mui/material";
import { Iconify } from "src/components";
import moment from "moment";

export default function CurrentWeekCard({ weeklyTimeCount }) {
  const [startDate, setStartDate] = useState(moment().startOf("isoWeek"));
  const [endDate, setEndDate] = useState(moment().endOf("isoWeek"));

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours > 0) {
      return `${hours} hr ${minutes} min`;
    }
    return `${minutes} min`;
  };

  return (
    <div>
      <div>
        <Card sx={{ mt: 2, border: "2px dotted", borderColor: "primary.main" }}>
          {/* <Card sx={{ mt: 2,}}> */}
          <CardContent className="duration-card-content pb-2">
            <Typography fontWeight={"bold"} textAlign={"center"} sx={{ mb: 1 }}>
              Current Week
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              padding="0px 8px"
            >
              <div className="d-flex align-items-center">
                <Iconify
                  icon="fluent-mdl2:date-time-mirrored"
                  // icon="ph:clock-countdown-duotone"
                  className="duration-icon"
                />
                <Typography
                  variant="body2"
                  fontWeight={600}
                  ml={1}
                  display="flex"
                  alignItems="center"
                >
                  {/* <Iconify
                    icon="simple-line-icons:calender"
                    style={{ marginRight: "5px" }}
                  /> */}
                  {startDate.format("D MMM")} - {endDate.format("D MMM")}
                </Typography>
              </div>
              <div>
                {weeklyTimeCount > 0 ? (
                  <>
                    <Typography className="duration-text">
                      {formatDuration(weeklyTimeCount)}
                    </Typography>
                    <Typography variant="body2">Spent</Typography>
                  </>
                ) : (
                  <Typography
                    className="d-flex align-items-center"
                    variant="body2"
                    color="textSecondary"
                  >
                    <Iconify
                      icon="dashicons:warning"
                      sx={{
                        marginRight: "5px",
                        fontSize: "14px",
                        color: "#ffaa00",
                      }}
                    />
                   No time spent
                  </Typography>
                )}
              </div>
            </Stack>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
