import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Badge,
  Typography,
} from "@mui/material";
// components
import Iconify from "../../components/Iconify";
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "src/hooks";
import { get_from_localStorage } from "src/utils";
import ActiveTimer from "./ActiveTimer";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_DESKTOP = "8vh";
const APPBAR_MOBILE = "8vh";

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: "#f7f9f8",
  // backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    // minHeight: APPBAR_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const navigate = useNavigate();
  const {
    dispatch_get_search_visibility,
    dispatch_set_search_visibility,
    dispatch_get_user_profile,
    badge,
  } = useAppContext();
  let visible = dispatch_get_search_visibility();
  const onPlansClick = () => {
    window.open(
      `${window.location.origin}/plansLink?token=${get_from_localStorage(
        "token"
      )}`
    );
  };
  // ---------------------------------------------------------
  return (
    <RootStyle id="mui-root-style">
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {visible && <Searchbar />}


        <Box sx={{ flexGrow: 1 }} />
        <ActiveTimer />
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <Button onClick={onPlansClick} variant="outlined">
            <Typography fontWeight={"bold"}>Upgrade</Typography>
            <Iconify
              icon="tabler:premium-rights"
              sx={{ height: "24px", width: "24px", color: "#dbb90f" }}
            />
          </Button> */}
          <IconButton
            sx={{ bgcolor: "#fff" }}
            onClick={() => navigate("/chats")}
          >
            <Badge
              variant="dot"
              badgeContent=""
              color="error"
              invisible={badge.chat <= 0}
            >
              <Iconify
                icon="heroicons-outline:chat"
                sx={{ width: "24px", height: "24px", color: "rgb(0, 171, 85)" }}
              />
            </Badge>
          </IconButton>
          <Button
            // variant="outlined"
            sx={{ bgcolor: "#fff" }}
            onClick={() => navigate("/workspaces/create")}
          >
            <Iconify
              icon="basil:add-outline"
              sx={{ width: "24px", height: "24px", mr: 1 }}
            />
            Create workspace
          </Button>
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
