import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import {
  Card,
  CardContent,
  Button,
  useTheme,
  Typography,
  CardHeader,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { _get_time_stats } from "src/DAL/dashboard";
import { Iconify } from "src/components";

export default function WeeklyChart({ setWeeklyTimeCount }) {
  const [weekData, setWeekData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("isoWeek"));
  const [endDate, setEndDate] = useState(moment().endOf("isoWeek"));
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      background: "transparent",
      toolbar: { show: false },
    },
    colors: [theme.palette.primary.main],
    fill: {
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: "25%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: "#E0E0E0",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: weekData.length > 0 ? weekData.map((item) => item.date) : [],
      labels: {
        formatter: (value) => moment(value).format("dddd"),
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },

    tooltip: {
      y: {
        formatter: (val) => {
          const hours = Math.floor(val / 3600);
          const minutes = Math.floor((val % 3600) / 60);
          return `${hours} hr ${minutes} min`;
        },
      },
      x: {
        formatter: (value, { dataPointIndex }) => {
          const dayData = weekData[dataPointIndex];
          //   const fullDayName = moment(dayData.date).format("dddd");
          const formattedDate = moment(dayData.date).format("MMMM D, YYYY");
          //   return `${fullDayName}, ${formattedDate}`;
          return `${formattedDate}`;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          const hours = Math.floor(val / 3600);
          return `${hours} h`;
        },
      },
    },
  };

  const chartData = {
    series: [
      {
        name: "Duration",
        data: weekData.length > 0 ? weekData.map((item) => item.hours) : [],
      },
    ],
  };

  const getTimeStatsData = async () => {
    const data = {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
    };
    const response = await _get_time_stats(data);
    if (response.code === 200) {
      sumWeeklyDuration(response.time_stats);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const sumWeeklyDuration = (timeStats) => {
    const weekDuration = [
      { day: "Mon", hours: 0, date: "" },
      { day: "Tue", hours: 0, date: "" },
      { day: "Wed", hours: 0, date: "" },
      { day: "Thu", hours: 0, date: "" },
      { day: "Fri", hours: 0, date: "" },
      { day: "Sat", hours: 0, date: "" },
      { day: "Sun", hours: 0, date: "" },
    ];

    weekDuration.forEach((_, index) => {
      const currentDay = moment(startDate).add(index, "days");
      weekDuration[index].date = currentDay.format("YYYY-MM-DD");
      weekDuration[index].day = currentDay.format("D MMM");
    });

    timeStats.forEach((task) => {
      task.daily_stats.forEach((stats) => {
        const date = stats.date;
        const duration = stats.duration;
        const dayIndex = moment(date).isoWeekday() - 1; // Adjust for Monday-based index

        if (weekDuration[dayIndex] && duration) {
          weekDuration[dayIndex].hours += duration;
        }
      });
    });

    setWeekData(weekDuration);

    // Calculate total current weekly duration for dashboard current week card
    let totalWeekDuration = 0;
    for (let i = 0; i < weekDuration.length; i++) {
      totalWeekDuration += weekDuration[i].hours;
    }

    const currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
    const currentEndDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
    const isCurrentWeek =
      startDate.format("YYYY-MM-DD") === currentStartDate &&
      endDate.format("YYYY-MM-DD") === currentEndDate;
    if (isCurrentWeek) {
      setWeeklyTimeCount(totalWeekDuration);
    }
  };

  const goToPreviousWeek = () => {
    const newStartDate = moment(startDate).subtract(1, "isoWeek");
    const newEndDate = moment(newStartDate).endOf("isoWeek");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const goToNextWeek = () => {
    const newStartDate = moment(startDate).add(1, "isoWeek");
    const newEndDate = moment(newStartDate).endOf("isoWeek");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    getTimeStatsData();
  }, [startDate, endDate]);

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader
        title={
          <div className="d-flex align-items-center justify-content-between">
            <Typography fontWeight="bold" fontSize="25px">
              Weekly Stats
            </Typography>
            <div className="custom-button-group">
              <button
                className="custom-btn custom-btn-primary custom-prev-btn"
                onClick={goToPreviousWeek}
              >
                <span className="custom-icon custom-icon-chevron-left"></span>
              </button>
              <button
                className="custom-btn custom-btn-primary custom-next-button"
                onClick={goToNextWeek}
              >
                <span className="custom-icon custom-icon-chevron-right"></span>
              </button>
            </div>
          </div>
        }
      />
      <CardContent sx={{ padding: "8px !important" }}>
        <Typography
          variant="body2"
          fontWeight={600}
          ml={2}
          display="flex"
          alignItems="center"
        >
          <Iconify
            icon="simple-line-icons:calender"
            style={{ marginRight: "5px" }}
          />
          {startDate.format("D MMM")} - {endDate.format("D MMM")}
        </Typography>
        <ApexCharts
          options={chartOptions}
          series={chartData.series}
          type="bar"
          height={400}
        />
      </CardContent>
    </Card>
  );
}
