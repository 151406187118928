import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Dialog as MuiDialog,
  TextField,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import Iconify from "src/components/Iconify";
import {
  _add_manual_time_slot,
  _delete_time_slot,
  _edit_time_slot,
  _time_slots_list,
} from "src/DAL/taskDetailDrawer";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

export default function TimeSlotsDialog({
  open,
  onClose,
  task,
  timeBlockID,
  formatTimeFromSeconds,
}) {
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // ---------------------------------------------
  const [editOpen, setEditOpen] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  // -------------------------------------------
  const [addNewSlotDialog, setAddNewSlotDialog] = useState(false);
  const [startTimeManual, setStartTimeManual] = useState("");
  const [endTimeManual, setEndTimeManual] = useState("");
  // -------------------------------------------
  const { enqueueSnackbar } = useSnackbar();

  const isEditSlotDisabled = !startTime || !endTime || endTime <= startTime;
  const isAddSlotDisabled =
    !startTimeManual || !endTimeManual || endTimeManual <= startTimeManual;

  const handleMenuButtonOpen = (event, slot) => {
    setMenuOpen(event.currentTarget);
    setSelectedSlot(slot);
    console.log(selectedSlot, "Selected Time Slot");
  };

  const handleMenuButtonClose = () => {
    setMenuOpen(null);
  };

  const handleEditOpen = () => {
    setStartTime(moment(selectedSlot.start_time).format("YYYY-MM-DDTHH:mm"));
    setEndTime(moment(selectedSlot.end_time).format("YYYY-MM-DDTHH:mm"));
    setEditOpen(true);
  };

  const handleAddSlotOpen = () => {
    setAddNewSlotDialog(true);
  };

  const handleOpenConfirmDelete = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDelete = () => {
    setConfirmDialogOpen(false);
  };

  const getTimeSlots = async (timeBlockID) => {
    setLoading(true);
    const response = await _time_slots_list(timeBlockID);
    if (response.code === 200) {
      setTimeSlots(response.time_slots);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };

  const editTimeSlot = async () => {
    const req_obj = {
      time_slot_id: selectedSlot._id,
      start_time: moment(startTime).toISOString(),
      end_time: moment(endTime).toISOString(),
    };

    setLoading(true);
    const response = await _edit_time_slot(req_obj);
    if (response.code === 200) {
      enqueueSnackbar("Time slot updated successfully!", {
        variant: "success",
      });
      setEditOpen(false);
      setSelectedSlot(null);
      getTimeSlots(timeBlockID);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };

  const deleteTimeSlot = async () => {
    if (selectedSlot.end_time === null) {
      enqueueSnackbar("Cannot delete an active time slot!", {
        variant: "error",
      });
      return setConfirmDialogOpen(false);
    }
    setLoading(true);
    const response = await _delete_time_slot(selectedSlot._id);
    if (response.code === 200) {
      enqueueSnackbar("Time slot deleted successfully!", {
        variant: "success",
      });
      setSelectedSlot(null);
      getTimeSlots(timeBlockID);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
    setConfirmDialogOpen(false);
  };

  const addNewTimeSlot = async () => {
    const req_obj = {
      task_id: task._id,
      start_time: moment(startTimeManual).toISOString(),
      end_time: moment(endTimeManual).toISOString(),
    };

    const response = await _add_manual_time_slot(req_obj);
    setLoading(true);
    if (response.code === 200) {
      enqueueSnackbar("Time slot created successfully!", {
        variant: "success",
      });
      console.log("response_API", response);
      getTimeSlots(timeBlockID);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setAddNewSlotDialog(false);
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      getTimeSlots(timeBlockID);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <div
          style={{
            height: "500px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <DialogTitle className="d-flex align-items-center justify-content-between">
                <span>Time Slots</span>
                <div>
                  <Button
                    onClick={handleAddSlotOpen}
                    color="primary"
                    size="small"
                    startIcon={<Iconify icon="material-symbols:add" />}
                  >
                    Manual Slot
                  </Button>
                </div>
              </DialogTitle>

              <DialogContent
                sx={{
                  paddingBottom: 1,
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <Grid container className="time-slots-heading">
                  <Grid item xs={1}>
                    <Typography className="slots-title ms-1">No.</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="slots-title">Start Time</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="slots-title">Stop Time</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className="slots-title">Duration</Typography>
                  </Grid>
                </Grid>
                {timeSlots?.map((slot, index) => (
                  <Grid container key={index} className="time-slots">
                    <Grid item xs={1} className="d-flex align-items-center">
                      <Typography className="slots-title ms-2">
                        {index + 1}.
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className="d-flex align-items-center">
                      <Typography className="time-date-day">
                        {moment
                          .utc(slot.start_time)
                          .local()
                          .format("ddd, DD-MMM-YYYY hh:mm A")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className="d-flex align-items-center">
                      <Typography className="time-date-day">
                        {slot.end_time
                          ? moment
                              .utc(slot.end_time)
                              .local()
                              .format("ddd, DD-MMM-YYYY hh:mm A")
                          : "Active"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          {/* <Iconify
                            icon="pajamas:timer"
                            // fontSize="18px"
                            color="primary.main"
                            marginRight="6px"
                            padding="1px 0px"
                          /> */}
                          <Typography className="slots-title">
                            {slot.end_time
                              ? formatTimeFromSeconds(slot.duration)
                              : null}
                          </Typography>
                        </div>
                        <div className="d-flex align-items-center">
                          {slot.end_time ? (
                            <div>
                              {slot.slot_type === "system" ? (
                                <Iconify
                                  icon="mdi:check-decagram"
                                  color="primary.main"
                                />
                              ) : (
                                <Iconify
                                  // icon="fluent-mdl2:edit-contact"
                                  icon="mdi:check-decagram"
                                  color="#999999"
                                />
                              )}
                            </div>
                          ) : null}

                          <IconButton
                            className="p-1 ms-1"
                            disabled={slot.end_time === null}
                            onClick={(event) =>
                              handleMenuButtonOpen(event, slot)
                            }
                          >
                            <Iconify icon="mdi:dots-vertical" fontSize={18} />
                          </IconButton>
                          <Menu
                            anchorEl={menuOpen}
                            open={Boolean(menuOpen)}
                            onClose={handleMenuButtonClose}
                            PaperProps={{
                              style: {
                                boxShadow: "0px 1px 3px #F3F3F3",
                              },
                            }}
                          >
                            {/* Edit slot */}
                            <MenuItem
                              onClick={() => {
                                handleEditOpen();
                                handleMenuButtonClose();
                              }}
                            >
                              <Iconify
                                icon="mdi:pencil-outline"
                                fontSize={18}
                                style={{ marginRight: "8px" }}
                              />
                              Edit
                            </MenuItem>
                            {/* Delete slot */}
                            <MenuItem
                              onClick={() => {
                                handleOpenConfirmDelete();
                                handleMenuButtonClose();
                              }}
                            >
                              <Iconify
                                icon="mdi:delete-outline"
                                fontSize={18}
                                style={{ marginRight: "8px", color: "#DC362E" }}
                              />
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </div>
      </Dialog>

      {/* Delete Time Slot Dialog --------------------------------------- */}
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this time slot?
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            color="primary"
            onClick={() => deleteTimeSlot()}
          >
            Yes
          </LoadingButton>
          <Button onClick={handleCloseConfirmDelete} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Time Slot Dialog ------------------------------------------ */}
      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>Edit Time Slot</DialogTitle>
        <DialogContent sx={{ padding: "10px 24px" }}>
          <TextField
            label="Start Time"
            type="datetime-local"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
          <TextField
            label="End Time"
            type="datetime-local"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
          {isEditSlotDisabled && (
            <div className="d-flex align-items-center mt-2">
              <Iconify
                icon="dashicons:warning"
                color="#FF9800"
                fontSize="18px"
              />
              <Typography className="warning-message">
                {!startTime || !endTime
                  ? "Please choose both start and end times"
                  : "Start time should be set earlier than the end time"}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            color="primary"
            onClick={editTimeSlot}
            disabled={isEditSlotDisabled}
          >
            Save
          </LoadingButton>
          <Button onClick={() => setEditOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add New Time Slot Dialog ------------------------------------------ */}
      <Dialog
        open={addNewSlotDialog}
        onClose={() => setAddNewSlotDialog(false)}
      >
        <DialogTitle>Manual Time Slot</DialogTitle>
        <DialogContent sx={{ padding: "10px 24px" }}>
          <TextField
            label="Start Time"
            type="datetime-local"
            value={startTimeManual}
            onChange={(e) => setStartTimeManual(e.target.value)}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
          <TextField
            label="End Time"
            type="datetime-local"
            value={endTimeManual}
            onChange={(e) => setEndTimeManual(e.target.value)}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
          {isAddSlotDisabled && (
            <div className="d-flex align-items-center mt-2">
              <Iconify
                icon="dashicons:warning"
                color="#FF9800"
                fontSize="18px"
              />
              <Typography className="warning-message">
                {!startTimeManual || !endTimeManual
                  ? "Please choose both start and end times"
                  : "Start time should be set earlier than the end time"}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            color="primary"
            onClick={addNewTimeSlot}
            disabled={isAddSlotDisabled}
          >
            Add Slot
          </LoadingButton>
          <Button onClick={() => setAddNewSlotDialog(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
