import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Typography } from "@mui/material";
import Iconify from "../Iconify";
import { get_zone_date } from "src/utils";
import moment from "moment";


const CheckListDatePicker = ({ value, onChange, disabled, completed }) => {
  // const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (disabled === true) {
      return;
    }
    setOpen(true);
  };

  const getFormatted = (_format, _date) => {
    let date = _date ? _date : moment();
    const with_zone = get_zone_date(date, _format);
    return moment(with_zone);
  };

  const isDatePast = (_date) => {
    let target_date = getFormatted("YYYY-MM-DD", _date);
    let today_date = getFormatted("YYYY-MM-DD");
    let expired = target_date.isBefore(today_date);
    return expired;
  };

  const getColor = (date) => {
    if (!date) {
      return "#00AB55";
    }
    let expired = isDatePast(date);
    if (expired) {
      return completed == true ? "#00AB55" : "#c23232";
    }
    return "#00AB55";
  };

  const onDateChange = (newValue) => {
    onChange(newValue);
  };
  /* <<--------------------------------------------------------->> */
  return (
    <span id="checklist-date-picker">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Custom input"
          value={value ? value : ""}
          disabled={disabled}
          open={open}
          onClose={() => setOpen(false)}
          onChange={(newValue) => {
            onDateChange(newValue);
          }}
          disableHighlightToday
          // minDate={getMinDate("YYYY-MM-DD")}
          PopperProps={{
            disablePortal: true,
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 5],
                },
              },
              // opens under the input -------------
            //   {
            //     name: "flip",
            //     enabled: false,
            //   },
            //   {
            //     name: "preventOverflow",
            //     options: {
            //       boundary: "viewport",
            //     },
            //   },
            ],
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => {
            return (
              <Box
                className="pointer"
                onClick={handleOpen}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {inputProps.value ? (
                  <div
                    style={{
                      border: `1px dashed ${getColor(inputProps.value)}`,
                      padding: "1px 6px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: disabled
                          ? (theme) => theme.palette.text.disabled
                          : getColor(inputProps.value),
                        fontSize: "11px",
                        fontWeight: 500,
                      }}
                      ref={inputRef}
                      {...inputProps}
                    >
                      {moment(inputProps.value).format("DD MMM YYYY")}
                    </Typography>
                  </div>
                ) : (
                  <Typography
                    className="choose-target-date"
                    sx={{
                      fontSize: "12px",
                    }}
                    ref={inputRef}
                    {...inputProps}
                  >
                    Choose Target Date
                  </Typography>
                )}
              </Box>
            );
          }}
        />
      </LocalizationProvider>
    </span>
  );
};

export default CheckListDatePicker;
