import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
  Tooltip,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularLoader, Iconify, Scrollbar } from "src/components";
import { TASK_STATUS_OPTIONS } from "src/constants";
import { isColorLight } from "src/utils";
import { noTasks } from "src/assets";
import moment from "moment";
import OpenTaskDetail from "./OpenTaskDetail";

/* <<--------------------------------------------------------->> */
const CalendarComponent = ({ loading, events, getCalendarTasks }) => {
  console.log(events, "calendar_events");
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventsForDay, setEventsForDay] = useState([]);
  const navigate = useNavigate();

  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    setSelectedDate(clickedDate);
  //   const filteredEvents = events.filter((event) =>
  //     new Date(event.start).toISOString().split("T")[0] === clickedDate
  // );
    const filteredEvents = events.filter((event) => {
      const eventStartDate = moment(event.start).format("YYYY-MM-DD");
      return eventStartDate === clickedDate;
    }
  );


    setEventsForDay(filteredEvents);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // const navigateEvent = (data) => {
  //   console.log(data, "data");
  //   navigate(`/workspaces/projects/${data.project.id}`, {
  //     state: { task_id: data._id },
  //   });
  // };
  /* <<--------------------------------------------------------->> */

  /* <<--------------------------------------------------------->> */
  const getStatusProps = (value) => {
    if (!value) {
      return { color: "" };
    }
    if (String(value) === "0") {
      return { color: "#FFC107" };
    }
    let [found] = TASK_STATUS_OPTIONS.filter(
      (item) => String(item.value) === String(value)
    );
    if (found) {
      return found;
    }
  };
  const renderEventContent = (info) => {
    const { more, bg } = info.event._def.extendedProps;
    if (!more) {
      return <></>;
    }
    return (
      <div style={{ overflow: "hidden", width: "100%" }}>
        {/* <Tooltip title={more?.task_title}> */}
        <div
          style={{
            color: isColorLight(bg) ? "#000" : "#fff",
            backgroundColor: bg,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            padding: "2px 4px",
            // fontSize: "10px",
            fontWeight: "500",
            borderRadius: "3px",
          }}
        >
          {more?.task_title}
        </div>
        {/* </Tooltip> */}
      </div>
    );
  };
  /* <<--------------------------------------------------------->> */
  if (loading) {
    return (
      <>
        <CircularLoader />
      </>
    );
  }
  return (
    <div className="full-calendar">
      <FullCalendar
        dateClick={handleDateClick} // opens clicked date modal
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "today prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        eventContent={renderEventContent}
        // eventClick={onEventClick}
        events={events}
        initialView="dayGridMonth"
        // editable={true}
        // selectable={true}
        selectMirror={true}
        dayMaxEvents={1}
        weekends={true}
      />

      {/* Modal ---------------------------------------------- */}
      <Dialog fullWidth open={openModal} onClose={handleCloseModal}>
        <DialogTitle className="d-flex align-items-center">
          <Iconify
            icon="simple-line-icons:calender"
            style={{ marginRight: "5px" }}
          />
          {moment(selectedDate).format("D MMM YYYY")}
        </DialogTitle>
        <DialogContent
          sx={{ maxHeight: "300px", minHeight: "300px" }}
          className="pb-0"
        >
 
         {/* ------------------------------------------------------- */}
          {eventsForDay?.length > 0 &&
            eventsForDay?.map((event, index) => (
              <Scrollbar
                sx={{
                  maxHeight: "60vh",
                }}
                key={index}
              >
                <Stack spacing={1} sx={{ p: 0.5 }}>
                  <OpenTaskDetail
                    getCalendarTasks={getCalendarTasks}
                    task={event.more}
                  />
                </Stack>
              </Scrollbar>
            ))}
          {/* ----------------------No found-------------------------- */}
          {eventsForDay?.length === 0 && (
            <div className="d-flex justify-content-center h-100">
              <Box>
                <div className="d-flex justify-content-center mb-1">
                  <div style={{ width: "40%", margin: "auto" }}>
                    <img
                      width="100%"
                      src={noTasks}
                      style={{ margin: "auto" }}
                    />
                  </div>
                </div>
                <Typography
                  className="d-flex align-items-center justify-content-center mt-1"
                  color={(theme) => theme.palette.text.disabled}
                  variant="body2"
                  textAlign="center"
                  fontWeight="bold"
                >
                  Not Found !
                </Typography>
                <Typography
                  color={(theme) => theme.palette.text.disabled}
                  variant="body2"
                  textAlign="center"
                >
                  There are no tasks
                </Typography>
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CalendarComponent;
