import {
  Avatar,
  Button,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState, useLayoutEffect, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Iconify,
  MuiDrawer,
  Page,
  PriorityPopover,
  StatusPopover,
  TaskDatePick,
} from "src/components";
import { s3baseUrl } from "src/config";
import {
  ALLOWED_TASK_FILES_WITH_ICONS,
  TASK_STATUS_OPTIONS,
} from "src/constants";
import { _dashboard_tasks, _get_caldendar_tasks } from "src/DAL";
import {
  get_month_first_day,
  get_month_last_day,
  make_clickable_url_by_className,
} from "src/utils";
import CalendarComponent from "./components/CalendarComponent";
import WeekCalendar from "./components/WeekCalendar";
import DayCalendar from "./components/DayCalendar";
const get_view_dates = (tool) => {
  if (tool == "month") {
    return { start_date: moment().startOf("M"), end_date: moment().endOf("M") };
  }
  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }
  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
const get_dates = (tool) => {
  if (tool == "month") {
    return get_view_dates(tool);
  } else {
    return {
      start_date: moment().startOf(tool).toDate(),
      end_date: moment().endOf(tool).toDate(),
    };
  }
};
const TaskCalendar = ({ events = [] }) => {
  const [calendarView, setCalendarView] = useState("month");
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const getEventBg = (value) => {
    if (value === "" || value === null || value === undefined) {
      return "#d1cbcb"; /* dim grey */
    }
    let [found] = TASK_STATUS_OPTIONS.filter(
      (item) => String(item.value) === String(value)
    );
    if (found) {
      return found.color;
    }
    return "#d1cbcb"; /* dim grey */
  };
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [calenderEvents, setCalenderEvents] = useState(
    events
      .filter((event) => Boolean(event.end_date))
      .map((task) => {
        return {
          title: task.task_title,
          start: task.end_date,
          bg: getEventBg(task.task_status),
          more: task,
        };
      })
  );
  const [taskFiles, setTaskFiles] = useState([]);
  const [eventDetailOpen, setEventDetailOpen] = useState(false);
  const [loadingTaskFiles, setLoadingTaskFiles] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  /* <<--------------------------------------------------------->> */
  const getTaskFiles = async (data) => {
    console.log(data, "get-task-files");
  };
  const openEventDetail = (data) => {
    navigate(`/workspaces/projects/${data.project.id}`, {
      state: { task_id: data._id },
    });
    // setEventDetailOpen(true);
    // setTimeout(() => {
    //   make_clickable_url_by_className("dashboard-task-description");
    // }, 400);
  };
  const handleEventClick = (info) => {
    const { event, jsEvent, el, view } = info;
    const { _def } = event;
    console.log(_def, "_def");
    setSelectedEventData(_def.extendedProps.more);
    openEventDetail(_def.extendedProps.more);
  };
  const handleCustomEventClick = (event) => {
    setSelectedEventData(event);
    openEventDetail(event);
  };
  const closeEventDetail = () => {
    setSelectedEventData(undefined);
    setEventDetailOpen(false);
  };
  const getLocaleDate = (date, format = "YYYY-MM-DD hh:mm") => {
    if (!date) {
      return "";
    }
    const moment_date = moment(date).format(format);
    const locale_moment_date = moment_date.toLocaleString();
    return locale_moment_date;
  };

  // const getDashboardTasks = async () => {
  //   let first_day = get_month_first_day();
  //   let last_day = get_month_last_day();
  //   const data = { start_date: first_day, end_date: last_day };
  //   const result = await _dashboard_tasks(data);
  //   if (result.code === 200) {
  //     console.log(result, "dashboard-res");
  //     const { project_list } = result;
  //     const custom_events = project_list.web_calendar_tasks.map((task) => {
  //       return {
  //         title: task.task_title,
  //         start: task.end_date,
  //         bg: getEventBg(task.task_status),
  //         more: task,
  //       };
  //     });
  //     console.log(custom_events, "custom_events");
  //     setCalenderEvents(custom_events);
  //     setLoadingEvents(false);
  //   } else {
  //     setLoadingEvents(false);
  //     enqueueSnackbar(result.message, { variant: "error" });
  //   }
  // };
  const handleStatusChange = async (option) => {
    console.log(option, "new-val");
    const updateMoreObj = (_more) => {
      return { ..._more, task_status: option.value };
    };
    setCalenderEvents((prev) =>
      prev.map((ev) => {
        if (String(ev.more._id) === selectedEventData._id) {
          return { ...ev, more: updateMoreObj(ev.more) };
        }
        return ev;
      })
    );
    setSelectedEventData((prev) => {
      return { ...prev, task_status: option.value };
    });
  };
  const handlePriorityChange = async (option) => {
    console.log(option, "new-val");
    const updateMoreObj = (_more) => {
      return { ..._more, priority: option.value };
    };
    setCalenderEvents((prev) =>
      prev.map((ev) => {
        if (String(ev.more._id) === selectedEventData._id) {
          return { ...ev, more: updateMoreObj(ev.more) };
        }
        return ev;
      })
    );
    setSelectedEventData((prev) => {
      return { ...prev, priority: option.value };
    });
  };
  const handleTargetDateChange = async (_date) => {
    console.log(_date, "new-date");
    console.log(selectedEventData, "sl-ev");
    const updateMoreObj = (_more) => {
      return { ..._more, end_date: _date };
    };
    setCalenderEvents((prev) =>
      prev.map((ev) => {
        if (String(ev.more._id) === selectedEventData._id) {
          return { ...ev, more: updateMoreObj(ev.more) };
        }
        return ev;
      })
    );
    setSelectedEventData((prev) => {
      return { ...prev, end_date: _date };
    });
  };
  /* <<--------------------------------------------------------->> */
  useLayoutEffect(() => {
    // getDashboardTasks();
    return () => {
      setSelectedEventData(undefined);
      setCalenderEvents([]);
      setEventDetailOpen(false);
    };
  }, []);
  const getFileIconType = (path) => {
    if (!path) {
      return "";
    }
    let ext = path.split("/")[0];
    let found = undefined;
    ALLOWED_TASK_FILES_WITH_ICONS.map((item) => {
      item.baseTypes.map((_type) => {
        if (String(_type) === String(ext)) {
          found = item;
        }
      });
    });

    if (found) {
      return { file_type: "file", file_path: found.icon, color: found.color };
    }
    if (!found) {
      return { file_type: "image", file_path: path };
    }
  };
  const getTaskImages = () => {
    let files = [];
    let task = selectedEventData;
    const { image } = task;
    for (let path of image) {
      let data = getFileIconType(path);
      const { file_type, file_path, color } = data;
      files.push({
        path: file_path,
        type: file_type,
        db_path: path,
        color: color,
      });
    }
    return files;
  };
  /* <<--------------------------------------------------------->> */
  //////=============Custom Calendar======================///////////
  const handleChangeView = (view) => {
    let dates = get_dates(view);
    setCurrentDate(dates);
    setCalendarView(view);
  };
  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value == "next") {
      new_object = {
        start_date: moment(start_date)
          .add(1, calendarView)
          .startOf(calendarView)
          .toDate(),
        end_date: moment(end_date)
          .add(1, calendarView)
          .endOf(calendarView)
          .toDate(),
      };
    } else {
      new_object = {
        start_date: moment(start_date)
          .subtract(1, calendarView)
          .startOf(calendarView)
          .toDate(),
        end_date: moment(end_date)
          .subtract(1, calendarView)
          .endOf(calendarView)
          .toDate(),
      };
    }

    setCurrentDate(new_object);
  };
  useEffect(() => {
    console.log(currentDate, "cvashiasicoasjhasiov");
  }, [currentDate]);
  const handleChangeNextPrev = (action) => {
    if (calendarView == "month") {
      let classToClick = "fc-next-button";
      if (action == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }
    hanldeChangeDates(action);
  };
  const getCenteredText = () => {
    let text = "";
    if (calendarView == "month") {
      text = moment(currentDate.start_date).format("MMM YYYY");
    } else if (calendarView == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      if (
        moment(currentDate.start_date).format("MMM") !==
        moment(currentDate.end_date).format("MMM")
      ) {
        end_date = moment(currentDate.end_date).format("MMM DD");
      }
      text = `${month} ${start_date} - ${end_date}, ${year}`;

      if (
        moment(currentDate.start_date).format("YYYY") !==
        moment(currentDate.end_date).format("YYYY")
      ) {
        start_date = moment(currentDate.start_date).format("MMM DD, YYYY");
        end_date = moment(currentDate.end_date).format("MMM DD, YYYY");
        text = `${start_date} - ${end_date}`;
      }
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };
  const todayButtonClick = () => {
    if (calendarView == "month") {
      let classToClick = "fc-today-button";
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }
    setCurrentDate(get_dates(calendarView));
  };
  const getCalendarTasks = async () => {
    try {
      const req = {
        start_date: moment(currentDate.start_date).toISOString(),
        end_date: moment(currentDate.end_date).toISOString(),
      };
      const result = await _get_caldendar_tasks(req);
      if (result.code == 200) {
        setCalenderEvents(
          result.dashboard.task_list.map((task) => {
            return {
              title: task.task_title,
              start: task.end_date,
              bg: getEventBg(task.task_status),
              more: task,
            };
          })
        );
      }
    } catch (error) {
      console.log(error, " catched while fetching calendar tasks");
    }
  };
  useEffect(() => {
    getCalendarTasks();
  }, [currentDate]);
  return (
    // <Page title="Calendar">
    <>
      {/* <Stack direction="row" alignItems="center" mb={3}>
          <Typography variant="h4">Calender </Typography>
        </Stack> */}
      {/* <div className="d-flex justify-content-between custom-header"> */}
      <div className="row  x-gutter-0">
        <div className="col-12 col-md-4">
          <button
            className="today-button custom-btn custom-btn-primary"
            onClick={() => todayButtonClick()}
            disabled={
              moment().isSameOrAfter(currentDate.start_date) &&
              moment().isSameOrBefore(currentDate.end_date)
            }
          >
            Today
          </button>
          <div className="custom-button-group">
            <button
              className="custom-btn custom-btn-primary custom-prev-btn"
              onClick={() => handleChangeNextPrev("prev")}
            >
              <span className="custom-icon custom-icon-chevron-left"></span>
            </button>
            <button
              className="custom-btn custom-btn-primary custom-next-button"
              onClick={() => handleChangeNextPrev("next")}
            >
              <span className="custom-icon custom-icon-chevron-right"></span>
            </button>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="custom-title h-100 justify-content-center">
            {getCenteredText()}
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end">
          <div className="custom-button-group custom-button-group2">
            <button
              className={
                "custom-btn custom-btn-primary " +
                (calendarView !== "month" ? "inactive-view" : "")
              }
              onClick={() => handleChangeView("month")}
            >
              month
            </button>
            <button
              className={
                "custom-btn custom-btn-primary " +
                (calendarView !== "week" ? "inactive-view" : "")
              }
              onClick={() => handleChangeView("week")}
            >
              week
            </button>
            <button
              className={
                "custom-btn custom-btn-primary " +
                (calendarView !== "day" ? "inactive-view" : "")
              }
              onClick={() => handleChangeView("day")}
            >
              day
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
      {calendarView == "month" && (
        <CalendarComponent
          // loading={loadingEvents}
          events={calenderEvents}
          onEventClick={handleEventClick}
          getCalendarTasks={getCalendarTasks}
        />
      )}
      {calendarView == "week" && (
        <WeekCalendar
          events={calenderEvents}
          currentDate={currentDate}
          handleClick={handleCustomEventClick}
        />
      )}
      {calendarView == "day" && (
        <DayCalendar
          events={calenderEvents}
          currentDate={currentDate}
          handleClick={handleCustomEventClick}
        />
      )}
      <MuiDrawer
        title="Event details"
        isOpen={eventDetailOpen}
        onClose={() => closeEventDetail()}
      >
        {selectedEventData && (
          <Stack spacing={3}>
            <Typography fontWeight="bold">
              {selectedEventData.task_title}
            </Typography>
            {selectedEventData.task_description !== "" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedEventData.task_description,
                }}
              />
            )}
            {selectedEventData.task_description == "" && (
              <Typography textAlign="center" color="dimgray">
                No description added
              </Typography>
            )}
            <Grid columnSpacing={0.5} container alignItems="center">
              <Grid item xs={6}>
                <TaskDatePick
                  task={selectedEventData}
                  value={selectedEventData.end_date}
                  onChange={handleTargetDateChange}
                  hasPortal={false}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <div className="d-flex align-items-center justify-content-center ">
                  <Tooltip
                    title={`Created By ${selectedEventData.creator_profile.first_name} ${selectedEventData.creator_profile.last_name}`}
                  >
                    <Avatar
                      sx={{ height: "2rem", width: "2rem" }}
                      src={s3baseUrl + selectedEventData.creator_profile.image}
                    />
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="d-flex align-items-center justify-content-center ">
                  <StatusPopover
                    allowOpen={false}
                    onChange={(val) => handleStatusChange(val)}
                    value={selectedEventData.task_status}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="d-flex align-items-center justify-content-center ">
                  <PriorityPopover
                    allowOpen={false}
                    onChange={(val) => handlePriorityChange(val)}
                    value={selectedEventData.priority}
                  />
                </div>
              </Grid>
            </Grid>
            {/* <div id="description">
              <Typography gutterBottom fontWeight="bold">
                Description :
              </Typography>
              
            </div> */}
            <div id="project">
              <Typography gutterBottom fontWeight="bold">
                Project :
              </Typography>
              <Typography>{selectedEventData.project.name}</Typography>
            </div>
            {selectedEventData?.image?.length > 0 && (
              <div id="files">
                <Typography gutterBottom fontWeight="bold">
                  Task files :
                </Typography>
              </div>
            )}
            <Grid
              container
              // gap={2}
              spacing={1}
              // alignItems="stretch"
              direction={"row"}
              // justifyContent="center"
            >
              {getTaskImages().map((item, index) => {
                return (
                  <Grid sx={{ position: "relative" }} key={index} item xs={3}>
                    <div className="task-files-container" key={index}>
                      {item.type === "file" ? (
                        <a
                          href={s3baseUrl + item.db_path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Iconify
                            sx={{
                              height: "4rem",
                              width: "4rem",
                              color: item.color,
                            }}
                            icon={item.path}
                          />
                        </a>
                      ) : (
                        <a
                          href={s3baseUrl + item.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="add-task-img"
                            src={s3baseUrl + item.path}
                          />
                        </a>
                      )}
                    </div>
                    {/* <div className="del-task-file-btn">
                          <IconButton
                            disabled={updatingTask}
                            onClick={() => removeTaskFile(item.db_path)}
                          >
                            <Iconify
                              sx={{ color: "red" }}
                              icon="gridicons:cross-circle"
                            />
                          </IconButton>
                        </div> */}
                  </Grid>
                );
              })}
              {/* {getLocalImages().map((item, index) => {
                    return (
                      <Grid
                        sx={{ position: "relative" }}
                        key={index}
                        item
                        xs={3}
                      >
                        <div className="task-files-container" key={index}>
                          {item.type === "file" ? (
                            <Iconify
                              sx={{
                                height: "4rem",
                                width: "4rem",
                                color: item.color,
                              }}
                              icon={item.path}
                            />
                          ) : (
                            <img className="add-task-img" src={item.path} />
                          )}
                        </div>
                        <div className="del-task-file-btn">
                          <IconButton
                            disabled={updatingTask}
                            onClick={() => removeLocalImage(item, index)}
                          >
                            <Iconify
                              sx={{ color: "red" }}
                              icon="gridicons:cross-circle"
                            />
                          </IconButton>
                        </div>
                      </Grid>
                    );
                  })} */}
              {/* <Grid item xs={3}>
                    <div>
                      <label
                        htmlFor="icon-button-file"
                        // style={{ width: "fit-content" }}
                      >
                        <input
                          style={{ display: "none" }}
                          accept={FILE_ACCEPT_TYPE_STRING}
                          id="icon-button-file"
                          type="file"
                          value=""
                          onChange={handleFileChange}
                          multiple
                          disabled={updatingTask}
                        />
                        <Tooltip title="upload files">
                          <Button
                            disabled={updatingTask}
                            // color="primary"
                            variant="outlined"
                            aria-label="upload picture"
                            component="span"
                            // size="small"
                            sx={{ height: "4rem" }}
                          >
                            <Iconify
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                                width: "2rem",
                                height: "2rem",
                              }}
                              icon="codicon:add"
                            />
                          </Button>
                        </Tooltip>
                      </label>
                    </div>
                  </Grid> */}
            </Grid>
          </Stack>
        )}
      </MuiDrawer>
    </>
  );
  // </Page>
};

export default TaskCalendar;
