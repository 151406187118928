import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Paper,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Checkbox,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  Tooltip,
  Avatar,
  Badge,
  TextField,
} from "@mui/material";
import { get_zone_date, isColorLight, replacedText } from "src/utils";
import {
  LabelPopup,
  MuiDialog,
  PriorityPopover,
  StatusPopover,
  TaskCreatedAt,
  TaskDatePick,
  TaskDatePicker,
  TeamMembers,
  TeamPicker,
  UserAvatar,
} from "..";
import Iconify from "../Iconify";
import Label from "../Label";
import { useAppContext } from "src/hooks";
import { format, parseISO } from "date-fns";
import { _project_team, _update_time_track } from "src/DAL";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config";
import moment from "moment";
import TaskStartedBy from "./TaskStartedBy";
// ---------------------------------------------------------
const stack_props = {
  direction: "row",
  alignItems: "center",
  columnGap: 0.5,
};
const icon_color = "#637381";
const menu_item_icon_size = { height: "1rem", width: "1rem" };
// ---------------------------------------------------------
const TaskCard = ({
  task,
  variant,
  projectTeam,
  onOpenDetail,
  onOpenComments,
  onTaskAction,
  taskSection,
  onSetSelectedTask,
  projectSections,
  isDraggingOver,
  currentUserRole,
  taskTeam,
  projectTags,
  setProjectTags,
  onChangeTags,
  sections,
  getFilteredTasksBySection,
}) => {
  // ---------------------------------------------------------
  const { dispatch_get_user_profile } = useAppContext();
  const userProfile = dispatch_get_user_profile();
  if (variant === "default") {
    return (
      <DefaultVariant
        onSetSelectedTask={(task) => onSetSelectedTask(task)}
        onOpenComments={onOpenComments}
        onOpenDetail={onOpenDetail}
        projectTeam={projectTeam}
        task={task}
        taskSection={taskSection}
        onTaskAction={(action, data) => onTaskAction(action, data)}
        projectSections={projectSections}
        isDraggingOver={isDraggingOver}
        userProfile={userProfile}
        currentUserRole={currentUserRole}
        setProjectTags={setProjectTags}
        projectTags={projectTags}
        onChangeTags={onChangeTags}
        sections={sections}
        getFilteredTasksBySection={getFilteredTasksBySection}
      />
    );
  }
  if (variant === "disabled") {
    return (
      <DisabledVariant
        currentUserRole={currentUserRole}
        userProfile={userProfile}
        onSetSelectedTask={(task) => onSetSelectedTask(task)}
        onOpenComments={onOpenComments}
        onOpenDetail={onOpenDetail}
        projectTeam={projectTeam}
        task={task}
        taskSection={taskSection}
        onTaskAction={(action, data) => onTaskAction(action, data)}
      />
    );
  }
  if (variant === "dashboard") {
    return (
      <DashboardVariant
        onOpenDetail={onOpenDetail}
        task={task}
        onTaskAction={(action, data) => onTaskAction(action, data)}
      />
    );
  }
  if (variant === "allTasks") {
    return (
      <AllTasksVariant
        onSetSelectedTask={(task) => onSetSelectedTask(task)}
        onOpenComments={onOpenComments}
        onOpenDetail={onOpenDetail}
        taskTeam={taskTeam}
        task={task}
        userProfile={userProfile}
        onTaskAction={(action, data) => onTaskAction(action, data)}
        currentUserRole={task.role}
      />
    );
  }
};

const DefaultVariant = ({
  task,
  projectTeam,
  onOpenDetail,
  onOpenComments,
  onTaskAction,
  taskSection,
  onSetSelectedTask,
  projectSections,
  isDraggingOver,
  userProfile,
  currentUserRole,
  projectTags,
  setProjectTags,
  onChangeTags,
  getFilteredTasksBySection,
  sections,
}) => {
  const matches_break_point = useMediaQuery("(max-width:600px)");

  const {
    task_status,
    task_title,
    createdAt,
    end_date,
    priority,
    team,
    creator_profile,
    extra_status,
    role,
    time_track,
  } = task;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(null);
  const [openEstimatedTime, setOpenEstimatedTime] = useState();
  const [estimatedTime, setEstimatedTime] = useState("");
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  // const [tagExpand, setTagExpand] = useState(false);
  const { tagExpand, setTagExpand } = useAppContext();
  const { dispatch_get_user_profile } = useAppContext();
  // ---------------------------------------------------------
  /* task variants can be 'default','disabled' */

  const handleOpen = (e, task) => {
    console.log(task, "on-select-task");
    onSetSelectedTask(task);
    setOpen(e.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const handleOpenEstimatedtime = (e) => {
    setOpenEstimatedTime(e.target);
  };
  const handleCloseEstimatedTime = () => {
    setOpenEstimatedTime(null);
    setEstimatedTime("");
  };
  const handleOpenSubmenu = (e, task) => {
    e.stopPropagation();
    setSubmenuOpen(e.currentTarget);
  };
  const handleCloseSubmenu = () => {
    setSubmenuOpen(null);
  };
  const handleAction = (action, data) => {
    onTaskAction(action, data);
  };
  const handleCopy = (task) => {
    onTaskAction("copy_task_link", task);
  };
  const onStatusCheckChange = (e) => {
    let checked = e.target.checked;
    const data = {
      checked: checked,
      task: task,
      section: taskSection,
      is_checkbox: true,
    };
    handleAction("change_status", data);
  };
  const onStatusChange = (_data) => {
    const data = {
      task: task,
      section: taskSection,
      status: _data.value,
      is_checkbox: false,
    };
    handleAction("change_status", data);
  };
  const onPriorityChange = (_data) => {
    const data = { task: task, section: taskSection, priority: _data.value };
    handleAction("change_priority", data);
  };
  const onDateChange = (newDate) => {
    const data = { task: task, section: taskSection, endDate: newDate };
    handleAction("change_date", data);
  };
  const canRemoveTeam = (_targetUser, _task) => {
    console.log(currentUserRole, "currentUserRole");
    let current_user_id = userProfile.user_id;
    let target_user_id = _targetUser.user;
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (
      String(currentUserRole) === "client" ||
      String(currentUserRole) === "client_team"
    ) {
      return true;
    }
    if (String(current_user_id) === String(target_user_id)) {
      console.log("cannot remove yourself");
      return false;
    }
    if (String(currentUserRole) === "team") {
      let found_index_in_team = undefined;
      _task.team.map((item, index) => {
        if (String(item.user) === String(target_user_id)) {
          found_index_in_team = index;
          console.log(item, "ff");
        }
      });
      console.log(found_index_in_team, "found_index_in_team");
      if (
        String(current_user_id) ===
        String(_task.team_assign_by[found_index_in_team])
      ) {
        console.log("you can remove");
      }
      return true;
    }
    return false;
  };
  const onTeamChange = (target_user, task_team, is_selected, action) => {
    const data = {
      task: task,
      section: taskSection,
      taskTeam: task_team,
      targetUser: target_user,
      isSelected: is_selected,
      action: action,
    };
    console.log(data, "team-req");
    if (String(data.action) === "remove") {
      let res = canRemoveTeam(data.targetUser, data.task);
      console.log(res, "canRemoveTeam");
      if (res === false) {
        return;
      }
    }
    handleAction("change_members", data);
  };
  const isChecked = () => {
    if (String(task_status) === "3") {
      return true;
    }

    if (String(task_status) === "4") {
      return true;
    }
    return false;
  };
  const onMove = (e, move_to) => {
    e.stopPropagation();
    handleClose();
    handleCloseSubmenu();
    const data = {
      moveTo: move_to,
      sourceTaskSection: taskSection,
      sourceTask: task,
    };
    handleAction("move_task", data);
  };
  const allowDateAction = () => {
    if (task.creator_profile?.user_id === dispatch_get_user_profile().user_id) {
      return true;
    }
    if (task.user_task_status === true) {
      return true;
    }
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (String(currentUserRole) === "team") {
      return true;
    }
    if (
      String(currentUserRole) === "client" ||
      String(currentUserRole) === "client_team"
    ) {
      return true;
    }
    return false;
  };
  const allowMoveToWorkspace = () => {
    if (task.linking_status == "parent" && !task.linked_task_id) {
      return true;
    } else {
      return false;
    }
  };
  const excludedStatusOptions = () => {
    if (!currentUserRole) {
      return [5];
    }
    if (creator_profile.user_id === dispatch_get_user_profile().user_id) {
      return [5];
    }
    if (currentUserRole === "admin") {
      return [5];
    }
    if (currentUserRole === "manager") {
      return [5];
    }
    if (currentUserRole === "client" || currentUserRole === "client_team") {
      return [5];
    }
    if (currentUserRole === "team") {
      return [4, 5]; /* exclude verified status for team */
    }
  };
  const updateTimeTrack = async () => {
    if (!estimatedTime) {
      enqueueSnackbar("Please add estimated time", { variant: "error" });
      return;
    }
    try {
      const reqObj = {
        task_id: task._id,
        task_time: {
          ...task.time_track,
          estimated_time:
            estimatedTime && !estimatedTime.includes(":")
              ? estimatedTime.padStart(2, "0") + ":00"
              : estimatedTime &&
                estimatedTime.includes(":") &&
                estimatedTime.split(":")[1].length < 2
              ? estimatedTime.split(":")[0] +
                ":" +
                estimatedTime.split(":")[1].padStart(2, "0")
              : estimatedTime,
        },
      };
      const result = await _update_time_track(reqObj);
      if (result.code == 200) {
        handleCloseEstimatedTime();
        getFilteredTasksBySection(task.section_id);
      }
    } catch (error) {
      console.log(error, " catched while updating time track");
    }
  };
  const handleScroll = () => {
    const elm = document.getElementById(task._id);
    if (elm) {
      if (task.scroll_to_view === true) {
        setTimeout(() => {
          elm.scrollIntoView({ block: "center", behavior: "auto" });
          elm.classList.add("highlight-task");
        }, 500);
        // navigate(location.pathname, { state: null });
        setTimeout(() => {
          elm.classList.remove("highlight-task");
        }, 3500);
      }
    }
  };
  // useEffect(() => {
  //   handleScroll();
  // }, []);

  /* <<--------------------------------------------------------->> */
  return (
    <React.Fragment>
      <Paper
        id={task._id}
        onClick={() => {
          console.log(task, "task");
          onOpenDetail(task);
        }}
        elevation={3}
        className={`${isDraggingOver ? "is-dragging" : ""}`}
        sx={{
          width: 1,
          borderRadius: "0.3rem",
          p: 0.7,
          pt: 0.9,
          pb: 0.9,
          position: "relative",
          pr: 7.5,
        }}
      >
        <Stack spacing={0.3}>
          <Stack {...stack_props}>
            <Checkbox
              sx={{ mb: "auto", p: "4px" }}
              value={task_status}
              onChange={onStatusCheckChange}
              checked={isChecked()}
              onClick={(e) => e.stopPropagation()}
            />
            <Typography
              role="button"
              sx={{ cursor: "pointer", maxHeight: "128px", overflow: "hidden" }}
              // onClick={(e) => {
              //   // if(e.target.id=="clickablelink"){
              //   //   return;
              //   // }
              //   onOpenDetail(task);
              // }}
            >
              <div
                dangerouslySetInnerHTML={replacedText(task_title)}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              />
            </Typography>
          </Stack>
          <Grid container>
            <Grid
              sx={{ paddingLeft: "9px", display: "flex", alignItems: "center" }}
              item
              flexGrow={1}
            >
              <Grid id="actions" direction="row" container>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  item
                  sm={3}
                  xs={6}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    {/* {(time_track.estimated_time || role == "client") && ( */}
                    <TaskDatePick
                      hasPortal
                      value={end_date}
                      onChange={(newDate) => onDateChange(newDate)}
                      task={task}
                      disabled={!allowDateAction()}
                    />
                    {/* )} */}
                    {/* {!time_track.estimated_time && role !== "client" && (
                      <>
                        <div onClick={handleOpenEstimatedtime}>
                          <Label
                            variant="ghost"
                            color="error"
                            sx={{ cursor: "pointer" }}
                          >
                            Add Estimated Time!
                          </Label>
                        </div>
                        <Menu
                          open={Boolean(openEstimatedTime)}
                          anchorEl={openEstimatedTime}
                          onClose={handleCloseEstimatedTime}
                        >
                          <div style={{ padding: "10px", paddingTop: "5px" }}>
                            <Typography fontWeight={600}>
                              Add Estimated Time
                            </Typography>
                            <div>
                              <TextField
                                size="small"
                                label="Estimated Time"
                                sx={{ mt: 2 }}
                                value={estimatedTime}
                                placeholder="hh:mm"
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  // Remove non-digit characters
                                  inputValue = inputValue.replace(
                                    /[^0-9:]/g,
                                    ""
                                  );
                                  // Limit the length to 5 characters
                                  inputValue = inputValue.slice(0, 5);

                                  if (inputValue.includes(":")) {
                                    let temp = inputValue.split(":");
                                    if (temp[0].length < 2) {
                                      temp[0] = temp[0].padStart(2, "0");
                                      inputValue = temp.join(":");
                                    }
                                  }
                                  if (
                                    inputValue.length > 2 &&
                                    !inputValue.includes(":")
                                  ) {
                                    // Add a colon after the first two digits, if necessary
                                    inputValue = `${inputValue.slice(
                                      0,
                                      2
                                    )}:${inputValue.slice(2)}`;
                                  }

                                  // Use a regular expression to match the desired time format (hh:mm)
                                  const regex =
                                    /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                                  if (
                                    regex.test(inputValue) ||
                                    inputValue === ""
                                  ) {
                                    let temp = inputValue.split(":");
                                    if (Number(temp[1]) > 59) {
                                      if (Number(temp[0]) < 99) {
                                        temp[1] = String(
                                          Number(temp[1] - 60)
                                        ).padStart(2, "0");
                                        temp[0] = String(Number(temp[0]) + 1);
                                      } else {
                                        temp[1] = String(Number(temp[1] - 60));
                                      }
                                      inputValue = temp.join(":");
                                    }
                                    setEstimatedTime(inputValue);
                                  }
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              <Button
                                size="small"
                                variant="contained"
                                sx={{ mt: 1 }}
                                onClick={updateTimeTrack}
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </Menu>
                      </>
                    )} */}
                  </span>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  sm={5}
                  xs={6}
                >
                  <Stack
                    justifyContent="center"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tooltip
                      title={`created by ${
                        creator_profile.first_name +
                        " " +
                        creator_profile.last_name
                      }`}
                    >
                      <div onClick={(e) => e.stopPropagation()}>
                        <UserAvatar
                          sx={{ width: 35, height: 35 }}
                          image={creator_profile.image}
                          alt="task creator"
                          name={
                            creator_profile.first_name +
                            " " +
                            creator_profile.last_name
                          }
                        />
                      </div>
                    </Tooltip>
                    <TaskCreatedAt date={createdAt} />
                    <PriorityPopover
                      onChange={(data) => onPriorityChange(data)}
                      allowOpen
                      value={priority}
                    />
                    <StatusPopover
                      extraStatus={extra_status}
                      onChange={(data) => onStatusChange(data)}
                      allowOpen={true}
                      excludedOptions={excludedStatusOptions()}
                      value={task_status}
                    />
                    <div className="pointer">
                      <Tooltip title="Comments">
                        <Stack
                          onClick={() => onOpenComments(task)}
                          direction="row"
                          alignItems="center"
                          spacing={0.1}
                        >
                          <Badge
                            badgeContent=""
                            color="error"
                            invisible={task.task_comment_unread_count == 0}
                            variant="dot"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Iconify
                              sx={{
                                height: 17,
                                width: 17,
                                color: icon_color,
                              }}
                              icon="majesticons:comment-text"
                            />
                          </Badge>
                          <Typography variant="body2">
                            {task.task_comment.length}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </div>
                    <div onClick={() => handleCopy(task)} className="pointer">
                      <Tooltip title="Copy task link">
                        <div>
                          <Iconify
                            sx={{ height: 17, width: 17, color: icon_color }}
                            icon="clarity:copy-line"
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <LabelPopup
                      projectId={task.project.id}
                      projectTags={projectTags}
                      setProjectTags={setProjectTags}
                      task={task}
                      onChangeTags={onChangeTags}
                      sections={sections}
                      getFilteredTasksBySection={getFilteredTasksBySection}
                    />
                    {task.image.length > 0 && (
                      <div
                        className="pointer"
                        onClick={() => onOpenDetail(task)}
                      >
                        <Tooltip title="Attachments">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.1}
                          >
                            <Iconify
                              sx={{
                                height: 17,
                                width: 17,
                                color: icon_color,
                              }}
                              icon="mi:attachment"
                            />
                            <Typography variant="body2">
                              {task.image.length}
                            </Typography>
                          </Stack>
                        </Tooltip>
                      </div>
                    )}
                  </Stack>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Stack
                    justifyContent="space-between"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                    sx={{ marginTop: matches_break_point ? 0.3 : "initial" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="d-flex">
                      {team.length === 0 ? (
                        <Avatar
                          sx={{
                            height: 29,
                            width: 29,
                            bgcolor: isDraggingOver ? "#68ffa7" : "white",
                          }}
                        >
                          .
                        </Avatar>
                      ) : (
                        <Box
                          sx={{
                            marginLeft: matches_break_point
                              ? "27px"
                              : "initial",
                          }}
                        >
                          <TeamMembers
                            disabled={false}
                            allowOpen={true}
                            taskTeam={team}
                            projectTeam={projectTeam}
                            onChange={(
                              target_user,
                              taskTeam,
                              is_selected,
                              action
                            ) =>
                              onTeamChange(
                                target_user,
                                taskTeam,
                                is_selected,
                                action
                              )
                            }
                          />
                        </Box>
                      )}
                      <TeamPicker
                        onChange={(
                          target_user,
                          taskTeam,
                          is_selected,
                          action
                        ) =>
                          onTeamChange(
                            target_user,
                            taskTeam,
                            is_selected,
                            action
                          )
                        }
                        allowOpen
                        taskTeam={team}
                        projectTeam={projectTeam}
                      />
                    </div>
                    <div>
                      {(role == "team" ||
                        role == "admin" ||
                        role == "manager") &&
                        time_track.estimated_time && (
                          <div>
                            <Label
                              variant="ghost"
                              color="info"
                              startIcon={
                                <Iconify icon={"ic:outline-access-time"} />
                              }
                            >
                              Estimate: {time_track.estimated_time}
                            </Label>
                          </div>
                        )}
                      {(role == "team" ||
                        role == "admin" ||
                        role == "manager") &&
                        task_status == 3 &&
                        time_track.actual_time && (
                          <div>
                            <Label
                              variant="ghost"
                              color="info"
                              startIcon={
                                <Iconify icon={"ic:outline-access-time"} />
                              }
                            >
                              Completed: {time_track.actual_time}
                            </Label>
                          </div>
                        )}

                      {(role == "admin" || role == "manager") &&
                        time_track.final_time && (
                          <div>
                            <Label
                              variant="ghost"
                              color="info"
                              startIcon={
                                <Iconify icon={"ic:outline-access-time"} />
                              }
                            >
                              Billable: {time_track.final_time}
                            </Label>
                          </div>
                        )}
                      {(role == "client" || role == "client_team") &&
                        time_track.final_time &&
                        task_status == 3 && (
                          <div>
                            <Label
                              variant="ghost"
                              color="info"
                              startIcon={
                                <Iconify icon={"ic:outline-access-time"} />
                              }
                            >
                              Completed: {time_track.final_time}
                            </Label>
                          </div>
                        )}
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        {task.tag_colors.length > 0 && (
          <Stack
            direction={"row"}
            spacing={1}
            flexWrap={"wrap"}
            onClick={(e) => e.stopPropagation()}
            sx={{ pt: 1, pl: 0.9 }}
          >
            {task.tag_colors.map((tag) => (
              <Tooltip title={tag.name ? tag.name : ""} key={tag._id}>
                <div
                  style={{
                    backgroundColor: tag.color_code,
                    borderRadius: "5px",
                    minWidth: "50px",
                    maxWidth: "100px",
                    maxHeight: tagExpand ? "20px" : "7px",
                    minHeight: "7px",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    color: isColorLight(tag.color_code) ? "#000" : "#fff",
                    transition: "max-height 0.3s ease",
                    cursor: "pointer",
                  }}
                  className="px-1"
                  onClick={() => setTagExpand((prev) => !prev)}
                >
                  {tagExpand && tag.name}
                </div>
              </Tooltip>
            ))}
          </Stack>
        )}

        <div onClick={(e) => e.stopPropagation()}>
          <div className="task-card-menu-btn">
            <IconButton
              sx={{ p: "3px" }}
              onClick={(e) => {
                handleOpen(e, task);
              }}
            >
              <Iconify
                sx={{ color: icon_color }}
                icon="bi:three-dots-vertical"
              />
            </IconButton>
          </div>
          <Menu
            disableEnforceFocus
            anchorEl={open}
            open={Boolean(open)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{
              p: 0.5,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.5,
                mx: 0.5,
              },
            }}
          >
            {(currentUserRole == "admin" ||
              currentUserRole == "manager" ||
              task.creator_profile.user_id ==
                dispatch_get_user_profile().user_id) && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onTaskAction("del_task", task);
                  handleClose();
                }}
              >
                Delete
                <span style={{ marginLeft: "auto" }}>
                  <Iconify
                    sx={{ ml: "8px", ...menu_item_icon_size }}
                    icon="dashicons:trash"
                  />
                </span>
              </MenuItem>
            )}
            {(currentUserRole == "admin" || currentUserRole == "manager") &&
              allowMoveToWorkspace() && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    onTaskAction("move_to_workspace", task);
                    handleClose();
                  }}
                >
                  Copy to other workspace
                  <span style={{ marginLeft: "auto" }}>
                    <Iconify
                      sx={{ ml: "8px", ...menu_item_icon_size }}
                      icon="mingcute:copy-fill"
                    />
                  </span>
                </MenuItem>
              )}
            {projectSections && (
              <MenuItem onClick={handleOpenSubmenu}>
                Move to other section
                <span style={{ marginLeft: "auto" }}>
                  <Iconify
                    sx={{ ml: "8px", ...menu_item_icon_size }}
                    icon="mdi:rotate-3d-variant"
                  />
                </span>
              </MenuItem>
            )}
            {/*  */}
            {projectSections && (
              <Menu
                disableEnforceFocus
                anchorEl={submenuOpen}
                open={Boolean(submenuOpen)}
                onClose={handleCloseSubmenu}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  p: 0.5,
                  mt: 1.5,
                  ml: -1,
                  marginRight: "44rem",
                  "& .MuiMenuItem-root": {
                    typography: "body2",
                    borderRadius: 0.5,
                    mx: 0.5,
                  },
                  "& .MuiPaper-root": {
                    left: "923px",
                  },
                }}
              >
                {projectSections.map((item) => {
                  return (
                    <MenuItem onClick={(e) => onMove(e, item)} key={item._id}>
                      {item.section_title}
                    </MenuItem>
                  );
                })}
                {projectSections.length === 0 && (
                  <MenuItem disabled disableRipple>
                    <Typography
                      variant="body2"
                      sx={{ color: (theme) => theme.palette.text.disabled }}
                    >
                      No sections
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            )}
          </Menu>
        </div>
        {/* Working on task by user */}
        <div className="working-on-task" style={{ margin: "6px 0px 0px 6px" }}>
          {task?.time_tracking &&
            task?.time_tracking.map(
              (time_block) =>
                time_block.tracking_status === "start" && (
                  <div
                    className="d-flex align-items-center"
                    key={time_block.assignee.user_id}
                  >
                    <Iconify
                      icon="material-symbols:circle"
                      color="#3366FF"
                      fontSize="10px"
                      marginRight="5px"
                    />
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "#637381",
                        paddingRight: "3px",
                        fontWeight: 600,
                      }}
                    >
                      {time_block.assignee.user_id ===
                      dispatch_get_user_profile().user_id
                        ? "You're"
                        : `${time_block.assignee.first_name} ${time_block.assignee.last_name}`}
                    </Typography>

                    <Typography sx={{ fontSize: "10px", color: "#637381" }}>
                      currently working on this task
                    </Typography>
                  </div>
                )
            )}
        </div>
      </Paper>
    </React.Fragment>
  );
};
const AllTasksVariant = ({
  task,
  taskTeam,
  onOpenDetail,
  onOpenComments,
  onTaskAction,
  onSetSelectedTask,
  userProfile,
  currentUserRole,
}) => {
  const matches_break_point = useMediaQuery("(max-width:600px)");
  const {
    createdAt,
    end_date,
    creator_profile,
    image,
    order,
    priority,
    project_id,
  } = task;
  const { task_title, section_id, status, task_action_by } = task;
  const {
    task_action_id,
    task_comment,
    task_comment_count,
    task_description,
    teamAssignBy,
    extra_status,
  } = task;
  const { task_status, task_type, team, workspace_id, _id } = task;
  const [open, setOpen] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [taskProjectTeam, setTaskProjectTeam] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  // ---------------------------------------------------------
  /* task variants can be 'default','disabled' */

  const handleOpen = (e, task) => {
    console.log(task, "on-select-task");
    onSetSelectedTask(task);
    setOpen(e.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const handleOpenSubmenu = (e, task) => {
    e.stopPropagation();
    setSubmenuOpen(e.currentTarget);
  };
  const handleCloseSubmenu = () => {
    setSubmenuOpen(null);
  };
  const handleAction = (action, data) => {
    onTaskAction(action, data);
  };
  const handleCopy = (task) => {
    onTaskAction("copy_task_link", task);
  };
  const onStatusCheckChange = (e) => {
    let checked = e.target.checked;
    const data = {
      checked: checked,
      task: task,
      is_checkbox: true,
    };
    handleAction("change_status", data);
  };
  const onStatusChange = (_data) => {
    const data = {
      task: task,
      status: _data.value,
      is_checkbox: false,
    };
    handleAction("change_status", data);
  };
  const onPriorityChange = (_data) => {
    const data = { task: task, priority: _data.value };
    handleAction("change_priority", data);
  };
  const onDateChange = (newDate) => {
    console.log(newDate, "new-date-on-change");
    const data = { task: task, endDate: newDate };
    handleAction("change_date", data);
  };
  const canRemoveTeam = (_targetUser, _task) => {
    console.log(currentUserRole, "currentUserRole");
    let current_user_id = userProfile.user_id;
    let target_user_id = _targetUser.user;
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (String(current_user_id) === String(target_user_id)) {
      console.log("cannot remove yourself");
      return false;
    }
    if (String(currentUserRole) === "team") {
      let found_index_in_team = undefined;
      _task.team.map((item, index) => {
        if (String(item.user) === String(target_user_id)) {
          found_index_in_team = index;
          console.log(item, "ff");
        }
      });
      console.log(found_index_in_team, "found_index_in_team");
      if (
        String(current_user_id) ===
        String(_task.team_assign_by[found_index_in_team])
      ) {
        console.log("you can remove");
      }
      return true;
    }
    return false;
  };
  const onTeamChange = (target_user, task_team, is_selected, action) => {
    const data = {
      task: task,
      taskTeam: task_team,
      targetUser: target_user,
      isSelected: is_selected,
      action: action,
    };
    console.log(data, "team-req");
    if (String(data.action) === "remove") {
      let res = canRemoveTeam(data.targetUser, data.task);
      console.log(res, "canRemoveTeam");
      if (res === false) {
        return;
      }
    }
    handleAction("change_members", data);
  };
  const isChecked = () => {
    if (String(task_status) === "3") {
      return true;
    }

    if (String(task_status) === "4") {
      return true;
    }
    return false;
  };

  const allowDateAction = () => {
    return true;
    if (task.creator_profile.user_id === dispatch_get_user_profile().user_id) {
      return true;
    }
    if (task.user_task_status === true) {
      return true;
    }
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (String(currentUserRole) === "team") {
      return false;
    }
    return false;
  };
  const excludedStatusOptions = () => {
    if (!currentUserRole) {
      return [5];
    }
    if (creator_profile.user_id === dispatch_get_user_profile().user_id) {
      return [5];
    }
    if (currentUserRole === "admin") {
      return [5];
    }
    if (currentUserRole === "manager") {
      return [5];
    }
    if (currentUserRole === "client" || currentUserRole === "client_team") {
      return [5];
    }
    if (currentUserRole === "team") {
      return [4, 5]; /* exclude verified status for team */
    }
  };
  const getTaskProjectTeam = async () => {
    // setLoadingTeam(true);
    const result = await _project_team(null, task.project_id);
    if (result.code === 200) {
      setTaskProjectTeam(result.project_team_list.team);
      setLoadingTeam(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoadingTeam(false);
    }
  };
  useEffect(() => {
    return () => {
      setTaskProjectTeam([]);
    };
  }, []);
  const replacedText = (text) => {
    const tempText = text.replace(
      /(\b(https?:\/\/)?(www\.)?\S+\.\S+\b)/gi,
      (match, url) => {
        const fullURL = url.startsWith("http") ? url : `http://${url}`;
        return `<a href="${fullURL}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }
    );
    return { __html: tempText };
  };
  /* <<--------------------------------------------------------->> */
  return (
    <React.Fragment>
      <Paper
        id={task._id}
        onClick={() => console.log(task, "task")}
        elevation={3}
        sx={{
          width: 1,
          borderRadius: "0.3rem",
          p: 0.7,
          pt: 0.9,
          position: "relative",
          pr: 7.5,
        }}
      >
        <Stack spacing={0.3}>
          <Stack {...stack_props}>
            <Checkbox
              sx={{ mb: "auto", p: "4px" }}
              value={task_status}
              onChange={onStatusCheckChange}
              checked={isChecked()}
            />
            <Typography
              role="button"
              sx={{ cursor: "pointer", maxHeight: "128px", overflow: "hidden" }}
              onClick={(e) => {
                // if(e.target.id=="clickablelink"){
                //   return;
                // }
                onOpenDetail(task);
              }}
            >
              <div dangerouslySetInnerHTML={replacedText(task_title)} />
            </Typography>
          </Stack>
          <Grid container>
            <Grid
              sx={{ paddingLeft: "9px", display: "flex", alignItems: "center" }}
              item
              flexGrow={1}
            >
              <Grid id="actions" direction="row" container>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  item
                  sm={3}
                  xs={6}
                >
                  <span>
                    <TaskDatePick
                      hasPortal
                      value={end_date}
                      onChange={(newDate) => onDateChange(newDate)}
                      task={task}
                      disabled={!allowDateAction()}
                    />
                  </span>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  sm={5}
                  xs={6}
                >
                  <Stack
                    justifyContent="center"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                  >
                    <Tooltip
                      title={`created by ${
                        creator_profile.first_name +
                        " " +
                        creator_profile.last_name
                      }`}
                    >
                      <div>
                        <UserAvatar
                          sx={{ width: 35, height: 35 }}
                          image={creator_profile.image}
                          alt="task creator"
                          name={
                            creator_profile.first_name +
                            " " +
                            creator_profile.last_name
                          }
                        />
                      </div>
                    </Tooltip>
                    <TaskCreatedAt date={createdAt} />
                    <PriorityPopover
                      onChange={(data) => onPriorityChange(data)}
                      allowOpen
                      value={priority}
                    />
                    <StatusPopover
                      onChange={(data) => onStatusChange(data)}
                      extraStatus={extra_status}
                      allowOpen={true}
                      excludedOptions={excludedStatusOptions()}
                      value={task_status}
                    />
                    <div className="pointer">
                      <Tooltip title="Comments">
                        <Stack
                          onClick={() => onOpenComments(task)}
                          direction="row"
                          alignItems="center"
                          spacing={0.1}
                        >
                          <Badge
                            badgeContent=""
                            color="error"
                            invisible={task.task_comment_unread_count == 0}
                            variant="dot"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Iconify
                              sx={{
                                height: 17,
                                width: 17,
                                color: icon_color,
                              }}
                              icon="majesticons:comment-text"
                            />
                          </Badge>
                          <Typography variant="body2">
                            {task.task_comment.length}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </div>
                    <div onClick={() => handleCopy(task)} className="pointer">
                      <Tooltip title="Copy task link">
                        <div>
                          <Iconify
                            sx={{ height: 17, width: 17, color: icon_color }}
                            icon="clarity:copy-line"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Stack
                    justifyContent="flex-start"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                    sx={{ marginTop: matches_break_point ? 0.3 : "initial" }}
                  >
                    {team.length === 0 ? (
                      <Avatar
                        sx={{
                          height: 29,
                          width: 29,
                        }}
                      >
                        .
                      </Avatar>
                    ) : (
                      <Box
                        sx={{
                          marginLeft: matches_break_point ? "27px" : "initial",
                        }}
                      >
                        <TeamMembers
                          disabled={false}
                          allowOpen={true}
                          taskTeam={team}
                          projectTeam={taskTeam}
                          onChange={(
                            target_user,
                            taskTeam,
                            is_selected,
                            action
                          ) =>
                            onTeamChange(
                              target_user,
                              taskTeam,
                              is_selected,
                              action
                            )
                          }
                        />
                      </Box>
                    )}
                    <TeamPicker
                      onChange={(target_user, taskTeam, is_selected, action) =>
                        onTeamChange(target_user, taskTeam, is_selected, action)
                      }
                      allowOpen
                      taskTeam={team}
                      projectTeam={taskProjectTeam}
                      onOpen={getTaskProjectTeam}
                      loading={loadingTeam}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        {(currentUserRole == "admin" ||
          currentUserRole == "manager" ||
          task.creator_profile.user_id ==
            dispatch_get_user_profile().user_id) && (
          <>
            <div className="task-card-menu-btn">
              <IconButton
                sx={{ p: "3px" }}
                onClick={(e) => handleOpen(e, task)}
              >
                <Iconify
                  sx={{ color: icon_color }}
                  icon="bi:three-dots-vertical"
                />
              </IconButton>
            </div>
            <Menu
              disableEnforceFocus
              anchorEl={open}
              open={Boolean(open)}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              sx={{
                p: 0.5,
                "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.5,
                  mx: 0.5,
                },
              }}
            >
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onTaskAction("del_task", task);
                  handleClose();
                }}
              >
                Delete
                <span style={{ marginLeft: "auto" }}>
                  <Iconify
                    sx={{ ml: "8px", ...menu_item_icon_size }}
                    icon="dashicons:trash"
                  />
                </span>
              </MenuItem>
            </Menu>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

// const DefaultVariant = React.memo(DefaultVariantz);
const DisabledVariant = ({
  task,
  projectTeam,
  onOpenDetail,
  onOpenComments,
  userProfile,
  currentUserRole,
  onTaskAction,
  taskSection,
}) => {
  const matches_break_point = useMediaQuery("(max-width:600px)");
  const {
    createdAt,
    end_date,
    image,
    creator_profile,
    order,
    priority,
    project_id,
  } = task;
  const { task_title, section_id, status, task_action_by, teamAssignBy } = task;
  const { task_action_id, task_comment, task_comment_count, task_description } =
    task;
  const { task_status, task_type, team, workspace_id, _id, extra_status } =
    task;
  const [open, setOpen] = useState(null);
  // const [tagExpand, setTagExpand] = useState(false);
  const { tagExpand, setTagExpand } = useAppContext();
  const { dispatch_get_user_profile } = useAppContext();
  // ---------------------------------------------------------
  /* task variants can be 'default','disabled' */
  const handleChangePriority = (_option, _index) => {
    const { value } = _option;
    console.log(_option, value);
  };
  const handleChangeStatus = (_option, _index) => {
    const { value } = _option;
    console.log(_option, value);
  };
  const excludedStatusOptions = () => {
    if (!currentUserRole) {
      return [5];
    }
    if (creator_profile.user_id === dispatch_get_user_profile().user_id) {
      return [5];
    }
    if (currentUserRole === "admin") {
      return [5];
    }
    if (currentUserRole === "manager") {
      return [5];
    }
    if (currentUserRole === "client" || currentUserRole === "client_team") {
      return [5];
    }
    if (currentUserRole === "team") {
      return [4, 5]; /* exclude verified status for team */
    }
  };
  const handleOpen = (e) => {
    setOpen(e.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const getFormattedDate = (date) => {
    const date_format = "dd MMM yyyy hh:mm aaa";
    if (!date) {
      return "Select date";
    }
    return format(parseISO(date), date_format);
  };
  const handleCopy = (task) => {
    onTaskAction("copy_task_link", task);
  };
  const replacedText = (text) => {
    const tempText = text.replace(
      /(\b(https?:\/\/)?(www\.)?\S+\.\S+\b)/gi,
      (match, url) => {
        const fullURL = url.startsWith("http") ? url : `http://${url}`;
        return `<a href="${fullURL}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }
    );
    return { __html: tempText };
  };
  // ---------------------------------------------------------
  return (
    <React.Fragment>
      <Paper
        onClick={() => {
          console.log(task, "task");
          onOpenDetail(task);
        }}
        elevation={3}
        sx={{
          width: 1,
          borderRadius: "0.3rem",
          p: 0.7,
          pt: 0.9,
          position: "relative",
          pr: 7.5,
        }}
      >
        <Stack spacing={0.3}>
          <Stack {...stack_props}>
            <Checkbox
              sx={{
                mb: "auto",
                p: "4px",
                maxHeight: "128px",
                overflow: "hidden",
              }}
              disabled
              onClick={(e) => e.stopPropagation()}
            />
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
                cursor: "pointer",
              }}
              role="button"
            >
              <div dangerouslySetInnerHTML={replacedText(task_title)} />
            </Typography>
          </Stack>
          <Grid container>
            <Grid
              sx={{
                paddingLeft: "9px",
                display: "flex",
                alignItems: "center",
              }}
              item
              flexGrow={1}
            >
              <Grid id="actions" direction="row" container>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  item
                  sm={3}
                  xs={6}
                >
                  {end_date && (
                    <Tooltip title="Target date/time">
                      <span onClick={(e) => e.stopPropagation()}>
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.text.disabled,
                            fontSize: "12px",
                          }}
                        >
                          {getFormattedDate(end_date, "DD MMM YYYY")}
                        </Typography>
                      </span>
                    </Tooltip>
                  )}
                  {/* <TaskDatePicker value={end_date} onChange={() => {}} disabled={true} /> */}
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  sm={5}
                  xs={6}
                >
                  <Stack
                    justifyContent="center"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tooltip
                      title={`created by ${
                        creator_profile.first_name +
                        " " +
                        creator_profile.last_name
                      }`}
                    >
                      <div>
                        <UserAvatar
                          sx={{ width: 35, height: 35 }}
                          image={creator_profile.image}
                          alt="task creator"
                          name={
                            creator_profile.first_name +
                            " " +
                            creator_profile.last_name
                          }
                        />
                      </div>
                    </Tooltip>
                    <TaskCreatedAt date={createdAt} />
                    <PriorityPopover
                      onChange={handleChangePriority}
                      allowOpen={false}
                      value={priority}
                    />
                    <StatusPopover
                      onChange={handleChangeStatus}
                      extraStatus={extra_status}
                      allowOpen={false}
                      value={task_status}
                      excludedOptions={excludedStatusOptions()}
                    />
                    <div className="pointer">
                      <Tooltip title="Comments">
                        <Stack
                          onClick={() => onOpenComments(task)}
                          direction="row"
                          alignItems="center"
                          spacing={0.1}
                        >
                          <Badge
                            badgeContent=""
                            color="error"
                            invisible={task.task_comment_unread_count == 0}
                            variant="dot"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Iconify
                              sx={{
                                height: 17,
                                width: 17,
                                color: icon_color,
                              }}
                              icon="majesticons:comment-text"
                            />
                          </Badge>
                          <Typography variant="body2">
                            {task.task_comment.length}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </div>
                    <div onClick={() => handleCopy(task)} className="pointer">
                      <Tooltip title="Copy task link">
                        <div>
                          <Iconify
                            sx={{ height: 17, width: 17, color: icon_color }}
                            icon="clarity:copy-line"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Stack
                    justifyContent="flex-start"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                    sx={{ marginTop: matches_break_point ? 0.3 : "initial" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Box
                      sx={{
                        marginLeft: matches_break_point ? "27px" : "initial",
                      }}
                    >
                      <TeamMembers
                        onChange={(
                          target_user,
                          taskTeam,
                          is_selected,
                          action
                        ) => {}}
                        taskTeam={team}
                        projectTeam={projectTeam}
                        allowOpen={true}
                        disabled={true}
                      />
                    </Box>
                    <TeamPicker
                      allowOpen={false}
                      taskTeam={team}
                      projectTeam={projectTeam}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          flexWrap={"wrap"}
          onClick={(e) => e.stopPropagation()}
        >
          {task.tag_colors.map((tag) => (
            <Tooltip title={tag.name ? tag.name : ""} key={tag._id}>
              <div
                style={{
                  backgroundColor: tag.color_code,
                  borderRadius: "5px",
                  minWidth: "50px",
                  maxWidth: "100px",
                  maxHeight: tagExpand ? "20px" : "7px",
                  minHeight: "7px",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: isColorLight(tag.color_code) ? "#000" : "#fff",
                  transition: "max-height 0.3s ease",
                  cursor: "pointer",
                }}
                className="px-1"
                onClick={() => setTagExpand((prev) => !prev)}
              >
                {tagExpand && tag.name}
              </div>
            </Tooltip>
          ))}
        </Stack>
        <div onClick={(e) => e.stopPropagation()}>
          <Tooltip title="not allowed">
            <div className="task-card-menu-btn">
              <IconButton disabled>
                <Iconify
                  sx={{ color: (theme) => theme.palette.text.disabled }}
                  icon="fluent:more-vertical-16-filled"
                />
              </IconButton>
            </div>
          </Tooltip>
          <Menu
            disableEnforceFocus
            anchorEl={open}
            open={Boolean(open)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{
              p: 0.5,
              mt: 1,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.5,
                mx: 0.5,
              },
            }}
          >
            <MenuItem>Move to</MenuItem>
            <MenuItem>Delete</MenuItem>
          </Menu>
        </div>
      </Paper>
    </React.Fragment>
  );
};

const DashboardVariant = ({ task, onOpenDetail, onTaskAction }) => {
  const matches_break_point = useMediaQuery("(max-width:600px)");
  const {
    createdAt,
    end_date,
    image,
    creator_profile,
    order,
    priority,
    project_id,
    extra_status,
  } = task;
  const { task_title, section_id, status, task_action_by, teamAssignBy } = task;
  const { task_action_id, task_comment, task_comment_count, task_description } =
    task;
  const { task_status, task_type, team, project_title, workspace_id, _id } =
    task;
  const [open, setOpen] = useState(null);
  const { dispatch_get_user_profile } = useAppContext();
  const userProfile = dispatch_get_user_profile();
  // ---------------------------------------------------------
  /* task variants can be 'default','disabled' */
  const handleChangePriority = (_option, _index) => {
    const { value } = _option;
    console.log(_option, value);
    const data = {
      task: task,
      new_priority: value,
    };
    onTaskAction("priority_change", data);
  };
  const handleChangeStatus = (_option, _index) => {
    const { value } = _option;
    console.log(_option, value);
    const data = {
      task: task,
      new_status: value,
    };
    onTaskAction("status_change", data);
  };

  const getFormattedDate = (date) => {
    if (!date) {
      return "Select date";
    }
    return get_zone_date(date, "DD MMM YYYY");
  };
  const handleNav = (_task) => {
    onOpenDetail(_task);
  };
  const handleCopy = (task) => {
    onTaskAction("copy_task_link", task);
  };
  const onStatusCheckChange = (e) => {
    let checked = e.target.checked;
    const data = {
      checked: checked,
      task: task,
    };
    onTaskAction("status_check", data);
  };

  const isChecked = () => {
    if (String(task_status) === "3") {
      return true;
    }

    if (String(task_status) === "4") {
      return true;
    }
    return false;
  };
  const onTargetDateTimeChange = (new_date_time) => {
    console.log(new_date_time, "new-date");
    const data = {
      new_time: new_date_time,
      task: task,
    };
    onTaskAction("target_date_time_change", data);
  };
  const replacedText = (text) => {
    const tempText = text.replace(
      /(\b(https?:\/\/)?(www\.)?\S+\.\S+\b)/gi,
      (match, url) => {
        const fullURL = url.startsWith("http") ? url : `http://${url}`;
        return `<a href="${fullURL}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }
    );
    return { __html: tempText };
  };
  // ---------------------------------------------------------
  return (
    <React.Fragment>
      <Paper
        id={task._id}
        onClick={() => console.log(task, "task")}
        elevation={3}
        sx={{
          width: 1,
          borderRadius: "0.3rem",
          p: 0.7,
          pt: 0.9,
          position: "relative",
          pr: 7.5,
        }}
      >
        <Stack spacing={0.3}>
          <Stack {...stack_props}>
            <Checkbox
              value={task_status}
              onChange={onStatusCheckChange}
              checked={isChecked()}
              sx={{
                mb: "auto",
                p: "4px",
                maxHeight: "128px",
                overflow: "hidden",
              }}
            />
            <Typography
              onClick={() => handleNav(task)}
              sx={{
                cursor: "pointer",
              }}
              role="button"
            >
              <div dangerouslySetInnerHTML={replacedText(task_title)} />
            </Typography>
          </Stack>
          <Grid container>
            <Grid
              sx={{
                paddingLeft: "9px",
                display: "flex",
                alignItems: "center",
              }}
              item
              flexGrow={1}
            >
              <Grid id="actions" direction="row" container>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  item
                  sm={3}
                  xs={6}
                >
                  <span>
                    <TaskDatePick
                      value={end_date}
                      hasPortal
                      onChange={onTargetDateTimeChange}
                      task={task}
                    />
                  </span>
                  {/* <TaskDatePicker value={end_date} onChange={() => {}} disabled={true} /> */}
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  sm={5}
                  xs={6}
                >
                  <Stack
                    justifyContent="center"
                    columnGap={0.7}
                    direction="row"
                    alignItems="center"
                  >
                    <Tooltip
                      title={`created by ${
                        creator_profile.first_name +
                        " " +
                        creator_profile.last_name
                      }`}
                    >
                      <div>
                        <UserAvatar
                          sx={{ width: 35, height: 35 }}
                          image={creator_profile.image}
                          alt="task creator"
                          name={
                            creator_profile.first_name +
                            " " +
                            creator_profile.last_name
                          }
                        />
                      </div>
                    </Tooltip>
                    <TaskCreatedAt date={createdAt} />
                    <PriorityPopover
                      onChange={handleChangePriority}
                      allowOpen={true}
                      value={priority}
                    />
                    <StatusPopover
                      onChange={handleChangeStatus}
                      extraStatus={extra_status}
                      allowOpen={true}
                      value={task_status}
                      excludedOptions={[5]}
                    />

                    <div onClick={() => handleCopy(task)} className="pointer">
                      <Tooltip title="Copy task link">
                        <div>
                          <Iconify
                            sx={{ height: 17, width: 17, color: icon_color }}
                            icon="clarity:copy-line"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Tooltip title="Project name">
                      <Typography
                        onClick={() => handleNav(task)}
                        noWrap
                        className="project-name-btn"
                      >
                        {task.project.name}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </React.Fragment>
  );
};

export default TaskCard;
TaskCard.propTypes = {
  task: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(["default", "disabled", "dashboard", "allTasks"])
    .isRequired,
};
