import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import Scrollbar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { noTasks } from "src/assets";
import { _get_all_start_pause_task_list } from "src/DAL/dashboard";
import { useAppContext } from "src/hooks";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import StartedTasksDetailCard from "src/components/task-components/StartedTasksDetailCard";
import OpenTaskDetail from "src/pages/task-calendar/components/OpenTaskDetail";

export default function StartedTasks() {
  const [allStartedTasks, setAllStartedTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isWorking, setIsWorking] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch_get_user_profile } = useAppContext();
  const userId = dispatch_get_user_profile().user_id;
  const navigate = useNavigate();

  // const handleNavigate = (task) => {
  //   const { section_id, _id } = task;
  //   const project_id = task?.project.id;
  //   navigate(
  //     `/workspaces/copied-task-link/${project_id}/${section_id}/${_id}`,
  //     { state: { task_id: _id } }
  //   );
  // };

  const getAllStartPauseTaskList = async () => {
    setLoading(true);
    const req_obj = { user_id: userId };
    const response = await _get_all_start_pause_task_list(req_obj);
    if (response.code === 200) {
      setAllStartedTasks(response.data);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userId) {
      getAllStartPauseTaskList();
    }
  }, [userId]);

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent sx={{ p: 1 }} style={{ paddingBottom: "8px" }}>
        <Typography fontWeight={"bold"} textAlign={"center"} sx={{ mb: 1 }}>
          Started Tasks
        </Typography>

        {/* Loader */}
        {loading && (
          <div
            className="centered-row"
            style={{ flexGrow: 1, minHeight: "10rem" }}
          >
            <CircularProgress />
          </div>
        )}

        {/* No Results */}
        {!loading && allStartedTasks.length === 0 && (
          <div className="centered-row" style={{ flexGrow: 1 }}>
            <Stack sx={{ height: "8.5rem", mb: 2 }}>
              <div className="d-flex justify-content-center mb-1">
                <div style={{ width: "30%", margin: "auto" }}>
                  <img width="100%" src={noTasks} style={{ margin: "auto" }} />
                </div>
              </div>
              <Typography
                color={(theme) => theme.palette.text.disabled}
                variant="body2"
                textAlign="center"
                fontWeight="bold"
              >
                Not Found!
              </Typography>
              <Typography
                color={(theme) => theme.palette.text.disabled}
                variant="body2"
                textAlign="center"
              >
                There are no started tasks
              </Typography>
            </Stack>
          </div>
        )}

        {/* Tasks List */}
        {!loading && allStartedTasks.length > 0 && (
          <Scrollbar sx={{ maxHeight: "60vh" }}>
            <Stack spacing={1} sx={{ p: 1 }}>
              {allStartedTasks.map((task, index) => (
                <div key={index}>
                  {/* <StartedTasksDetailCard
                    task={task}
                    handleNavigate={handleNavigate}
                  /> */}
                  <OpenTaskDetail isWorking={isWorking} task={task} />
                </div>
              ))}
            </Stack>
          </Scrollbar>
        )}
      </CardContent>
    </Card>
  );
}
