import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { _add_planned_time } from "src/DAL/taskDetailDrawer";

const timeOptions = [
  "30 min",
  "45 min",
  "1 hr",
  "1 hr 30 min",
  "2 hr",
  "2 hr 30 min",
  "3 hr",
  "4 hr",
];

export default function PlannedTimeInput({
  userId,
  task,
  getTaskDetail,
  canEditTask,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [plannedTime, setPlannedTime] = useState("");
  const [prevPlannedTime, setPrevPlannedTime] = useState("");

  const validatePlannedTime = (time) => {
    const timeRegex = /^([0-9]+):([0-5][0-9])$/;
    if (!time || !timeRegex.test(time) || time === "00:00") {
      enqueueSnackbar("Please enter a valid time (e.g., 01:30 HH:MM).", {
        variant: "warning",
      });
      return false;
    }
    return true;
  };

  const addPlannedTime = async () => {
    if (plannedTime === prevPlannedTime) {
      return;
    }

    if (plannedTime) {
      if (!validatePlannedTime(plannedTime)) {
        return;
      }
    } else {
    }

    const req_obj = {
      task_id: task?._id,
      user_id: userId,
      planned_time: plannedTime ? convertTimeToSeconds(plannedTime) : 0,
    };

    const response = await _add_planned_time(req_obj);
    if (response.code === 200) {
      getTaskDetail();
      enqueueSnackbar("Planned time saved successfully!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const convertTimeToSeconds = (time) => {
    if (!time) return 0;
    const hourMatch = time.match(
      /^(\d{1,2})\s*(hr|hrs|hour|hours)\s*(\d{1,2})?\s*(min)?/
    );
    if (hourMatch) {
      const hours = parseInt(hourMatch[1], 10);
      const minutes = hourMatch[3] ? parseInt(hourMatch[3], 10) : 0;
      return hours * 3600 + minutes * 60;
    }

    const minuteMatch = time.match(/^(\d{1,2})\s*(min)/);
    if (minuteMatch) {
      const minutes = parseInt(minuteMatch[1], 10);
      return minutes * 60;
    }

    const timeMatch = time.match(/^(\d{1,2}):(\d{2})$/);
    if (timeMatch) {
      const hours = parseInt(timeMatch[1], 10);
      const minutes = parseInt(timeMatch[2], 10);
      return hours * 3600 + minutes * 60;
    }
    return 0;
  };

  const convertSecondsToHHmm = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (task?.time_tracking) {
      for (let i = 0; i < task.time_tracking.length; i++) {
        const tracking = task.time_tracking[i];
        if (tracking.assignee?.user_id === userId) {
          if (tracking.planned_time) {
            setPlannedTime(convertSecondsToHHmm(tracking.planned_time || ""));
            setPrevPlannedTime(convertSecondsToHHmm(tracking.planned_time));
          } else {
            setPlannedTime("");
            setPrevPlannedTime("");
          }
          break;
        }
      }
    }
  }, [task]);

  return (
    <Autocomplete
      disabled={!canEditTask}
      size="small"
      options={timeOptions}
      value={plannedTime || ""}
      onChange={(event, selectedOption) => {
        if (!selectedOption) {
          setPlannedTime("");
        } else {
          const inSeconds = convertTimeToSeconds(selectedOption);
          const hhmmFormat = convertSecondsToHHmm(inSeconds);
          setPlannedTime(hhmmFormat);
        }
      }}
      onBlur={() => {
        addPlannedTime();
      }}
      renderOption={(props, option) => (
        <span {...props} style={{ fontWeight: "500" }}>
          {option}
        </span>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          value={plannedTime}
          // label=""
          label=""
          // InputLabelProps={{
          //   shrink: false, //hide label
          // }}
          className="plan-time-input"
          placeholder="hh:mm"
          type="text"
          fullWidth
          inputProps={{
            ...params.inputProps,
            maxLength: 5, // for max 5 input
          }}
          sx={{
            maxHeight: "36px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
            },
            "& .MuiInputLabel-root": {
              borderRadius: "5px",
            },
            // "& .MuiInputBase-input": {
            //   fontWeight: 600,
            // },
          }}
          onChange={(e) => {
            let value = e.target.value;
            const parts = value.split(":");
            if (parts.length > 2) {
              value = value.substring(0, value.lastIndexOf(":"));
            }
            if (value.length === 2 && !value.includes(":")) {
              value += ":";
            }
            if (parts.length === 2) {
              let hours = parts[0];
              let minutes = parts[1];
              if (parseInt(minutes, 10) > 59) {
                minutes = "59";
              }
              value = `${hours}:${minutes}`;
            }
            if (value.endsWith(":") && value.length > 3) {
              value = value.substring(0, value.length - 1);
            }
            setPlannedTime(value);
          }}
          onKeyDown={(e) => {
            const validKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
              "Enter",
            ];
            if (!validKeys.includes(e.key) && !/^[0-9]$/.test(e.key)) {
              e.preventDefault();
            }
          }}
        />
      )}
      freeSolo
      fullWidth
    />
  );
}
